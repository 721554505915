import HttpService from "@/modules/core/network/http-service";
import Axios from "axios";

class CRCHttpService extends HttpService {
  constructor() {
    super("crc");
  }

  createCRC(payload) {
    return this.post(payload);
  }

  getCRCs(payload) {
    return this.get(payload);
  }

  getCRC(id) {
    return this.get(id);
  }

  updateCRC({ data, id }) {
    return this.put(data, `${id}`);
  }

  updateManagers({ data, id }) {
    return this.put(data, `${id}/managers`);
  }

  updateStaff({ data, id }) {
    return this.patch(data, `${id}/staff`);
  }

  getUsersFromCRC({ id, data }) {
    this.resource = `crc/${id}/users`;
    const request = this.get(data);
    this.resource = `crc`;
    return request;
  }

  toggleStatusCRC(id) {
    return this.patch(null, `${id}/toggle-status`);
  }

  getAddressByZipCode(zipCode) {
    return Axios.get(
      `https://viacep.com.br/ws/${zipCode.replace("-", "")}/json/`
    );
  }
}

export default new CRCHttpService();
