import jwt_decode from "jwt-decode";

const TOKEN_KEY = "authToken";

class JwtService {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  isAuthenticated() {
    return !!localStorage.getItem(TOKEN_KEY);
  }

  async storeToken(token) {
    if (token) {
      localStorage.setItem(TOKEN_KEY, token);
      return await jwt_decode(token);
    }
  }

  logout() {
    localStorage.removeItem(TOKEN_KEY);
  }
}

export default new JwtService();
