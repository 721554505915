export default {
  crc: {
    noHasCRC: {
      title: "Você ainda não cadastrou nenhum CRC, deseja cadastrar?",
      btnNewCRC: "Cadastrar a primeira CRC"
    }
  },
  crcForm: {
    crcName: "Informações da CRC",
    cnpj: "CNPJ",
    labels: {
      address: "Endereço",
      zipCode: "CEP",
      number: "Número",
      complement: "Complemento",
      neighborhood: "Bairro",
      city: "Cidade",
      state: "Estado",
      description: "Descricão"
    },
    success: {
      updated: "CRC Atualizado com sucesso"
    },
    errors: {
      invalidZipCode: "CEP inválido",
      notfoundZipCode: "CEP não encontrado",
      notEmpaty: "Campo obrigatório"
    }
  },
  registerManager: {
    manager: "Gerente",
    createManager: "Cadastrar gerente",
    title: "Adicione ao menos um gerente a uma CRC",
    optional: "(Opcional)",
    create: "Criar",
    out: "Sair",
    add: "Adicionar"
  }
};
