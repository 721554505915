import HttpService from "@/modules/core/network/http-service";

class DonatorHttpService extends HttpService {
  constructor() {
    super("donator");
  }

  getDonator(payload) {
    return this.get(payload);
  }

  addDonator(payload) {
    return this.post(payload);
  }
}

export default new DonatorHttpService();
