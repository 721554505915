import { profiles } from "@/modules/core/enums/profiles.enum";
import { ContentLayout } from "@/modules/core/views/templates";

export default [
  {
    path: "/funcionarios",
    component: ContentLayout,
    sidebar: {
      name: "Funcionários",
      icon: "mdi-account",
      index: 2,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id
      ]
    },
    children: [
      {
        name: "users",
        path: "",
        meta: {
          title: "Funcionários",
          public: false,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id
          ]
        },
        component: () =>
          import(
            /* webpackChunkName: "account-authentication-home" */
            "@/modules/account/user/views/pages/Users.vue"
          )
      },
      {
        name: "AddUser",
        path: "novo-funcionario",
        meta: {
          title: "Cadastrar Funcionário",
          public: false,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.MANAGER_OPERATING.id
          ]
        },
        component: () =>
          import("@/modules/account/user/views/pages/FormUser.vue")
      },
      {
        name: "UpdateUser",
        path: "editar-funcionario/:id(\\d+)",
        meta: {
          title: "Editar Perfil",
          public: false,
          sidebar: "Funcionários",
          iconBack: true,
          mode: "EDIT",
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.MANAGER_OPERATING.id
          ]
        },
        component: () =>
          import("@/modules/account/user/views/pages/UserProfile.vue")
      },
      {
        name: "ViewUser",
        path: ":id(\\d+)",
        meta: {
          title: "Visualizar funcionário",
          public: false,
          iconBack: true,
          mode: "VIEW",
          permissions: [
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () =>
          import("@/modules/account/user/views/pages/UserProfile.vue")
      },
      {
        name: "UserProfile",
        path: "meu-perfil",
        meta: {
          title: "Meu Perfil",
          public: false,
          iconBack: false,
          mode: "VIEW_SELF",
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id,
            profiles.DRIVER.id,
            profiles.OPERATOR_BATCH.id,
            profiles.OPERATOR_STOCK.id,
            profiles.OPERATOR_RECONDITION.id,
            profiles.OPERATOR_UNMANUFACTURE.id
          ]
        },
        component: () =>
          import("@/modules/account/user/views/pages/UserProfile.vue")
      }
    ]
  }
];
