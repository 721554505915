import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import agenda from "@/modules/agenda/store/";
import authentication from "@/modules/account/authentication/store/";
import core from "@/modules/core/store/";
import crc from "@/modules/crc/store/";
import donator from "@/modules/donator/store";
import partner from "@/modules/partner/store/";
import recycler from "@/modules/recycler/store/";
import role from "@/modules/account/role/store/";
import stock from "@/modules/stock/store";
import user from "@/modules/account/user/store/";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication,
    role,
    user,
    partner,
    recycler,
    crc,
    core,
    agenda,
    stock,
    donator
  },
  plugins: [
    createPersistedState({
      key: "Gestão de Metarreciclagem",
      paths: ["role", "user", "core"]
    })
  ]
});
