import HttpService from "@/modules/core/network/http-service";

class DonatorHttpService extends HttpService {
  constructor() {
    super("donator");
  }

  getDonator(payload) {
    return this.get(payload);
  }

  getDonatorPF(payload) {
    return this.get("pf", { params: payload });
  }

  getDonatorPJ(payload) {
    return this.get("pj", { params: payload });
  }

  addDonator(payload) {
    return this.post(payload);
  }

  getDetailDonatorPJ(id) {
    return this.get(`pj/${id}`);
  }

  getDetailDonatorPF(id) {
    return this.get(`pf/${id}`);
  }

  async updateDonatorPF(id, payload) {
    const { data } = await this.patch(payload, `pf/${id}`);
    return data;
  }

  async updateDonatorPJ(id, payload) {
    const { data } = await this.patch(payload, `pj/${id}`);
    return data;
  }

  async getRequestDonations({ id, data }) {
    const respose = await this.get(`${id}/requests`, { params: data });
    return respose;
  }
}

export default new DonatorHttpService();
