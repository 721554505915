import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import ReconditioningService from "@/modules/stock/reconditioning/service/ReconditioningService";

export const getReconditioning = loading(async ({ commit }, payload) => {
  const { data } = await ReconditioningService.get(payload);

  return data;
});

export const changeSituation = loading(async ({ commit }, payload) => {
  const { data } = await ReconditioningService.chanteSituation(payload);

  return data;
});

export const resetChangeSituation = ({ commit }) => {
  commit(MutationTypes.RESET_CHANGE_SITUATION);
};

export const setChangeSituationData = ({ commit }, payload) => {
  commit(MutationTypes.SET_CHANGE_SITUATION_DATA, payload);
};
