export const createInitialState = () => {
  return {
    stepper: {
      currentStep: 1
    },
    form: {
      batch: undefined,
      registerDate: undefined
    },
    key: 1
  };
};

export const initialState = () => {
  return {
    create: createInitialState()
  };
};

export const state = {
  data: initialState()
};
