import HttpService from "@/modules/core/network/http-service";

class ReconditioningService extends HttpService {
  constructor() {
    super("stock/reconditioning");
  }

  chanteSituation({ id, body }) {
    return this.patch(body, `${id}/situation`);
  }
}

export default new ReconditioningService();
