import HttpService from "@/modules/core/network/http-service";

class PartnerHttpService extends HttpService {
  constructor() {
    super("partner");
  }

  createPartner(payload) {
    return this.post(payload);
  }

  async getById(id) {
    const { data } = await this.get(id);
    return data;
  }

  async getPevs(payload) {
    const { data } = await this.get(payload);
    return data;
  }

  async update(id, payload) {
    const { data } = await this.patch(payload, `${id}`);
    return data;
  }

  async togglePartnerStatus(id) {
    const { data } = await this.patch(null, `${id}/toggle-status`);
    return data;
  }

  async getPartners(filters) {
    const { data } = await this.get(filters);

    return data;
  }

  async addPEVToPartner(payload) {
    const { data } = await this.post(payload, `voluntary-delivery-point`);

    return data;
  }

  async getPartnerContracts(id, filters) {
    const { data } = await this.get(`${id}/contracts`, filters);

    return data;
  }

  async createContract(id, payload) {
    const { data } = await this.post(payload, `${id}/contracts`);

    return data;
  }
}

export default new PartnerHttpService();
