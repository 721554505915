import HttpService from "@/modules/core/network/http-service";

class AgendaHttpService extends HttpService {
  constructor() {
    super("collection-request");
  }

  getCollection(payload) {
    return this.get(payload);
  }
}

export default new AgendaHttpService();
