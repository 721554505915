function _blobToBase64(blob, callback, error) {
  if (blob instanceof Blob === false && blob instanceof File === false) {
    return;
  }
  const reader = new FileReader();
  reader.onload = function() {
    const dataUrl = reader.result;
    const base64 = dataUrl.split(",")[1];
    callback(base64);
  };
  reader.onerror = error;
  reader.readAsDataURL(blob);
}

export const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    _blobToBase64(blob, resolve, reject);
  });
};

export const blobToDataUrl = async blob => {
  return new Promise((resolve, reject) => {
    _blobToBase64(
      blob,
      base64 => {
        const dataUrl = `data:${blob.type};base64,${base64}`;
        resolve(dataUrl);
      },
      reject
    );
  });
};

export const downloadFileFromBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();

  URL.revokeObjectURL(url);
  link.remove();
};
