export const createDestinationInitialState = () => {
  return {
    stepper: {
      currentStep: 0,
      maxSteps: 3
    },
    selectedCrc: {
      id: undefined,
      name: undefined
    },
    selectedRecycler: [],
    selectedResidues: [],
    residuesInvalid: true
  };
};

export const state = {
  data: {
    createDestination: createDestinationInitialState()
  }
};
