import HttpService from "@/modules/core/network/http-service";

class EquipmentTypeService extends HttpService {
  constructor() {
    super("stock/equipment/equipment-type");
  }

  getEquipmentTypes(payload) {
    return this.get(payload);
  }

  getEquipmentTypesMostUsed(payload) {
    return this.get("most-used", { params: payload });
  }
}

export default new EquipmentTypeService();
