import RoutesAgenda from "@/modules/agenda/views/router";
import RoutesAuthentication from "@/modules/account/authentication/views/router";
import RoutesCollection from "@/modules/collection/views/router";
import RoutesCore from "@/modules/core/views/router";
import RoutesCRC from "@/modules/crc/views/router";
import RoutesDonator from "@/modules/donator/views/router";
import RoutesPanel from "@/modules/panel/views/router";
import RoutesPartner from "@/modules/partner/views/router";
import RoutesRecycler from "@/modules/recycler/views/router";
import RoutesStock from "@/modules/stock/router";
import RoutesUser from "@/modules/account/user/views/router";

export default [
  ...RoutesCore,
  ...RoutesAuthentication,
  ...RoutesUser,
  ...RoutesPanel,
  ...RoutesCRC,
  ...RoutesPartner,
  ...RoutesAgenda,
  ...RoutesRecycler,
  ...RoutesCollection,
  ...RoutesStock,
  ...RoutesDonator
];

/**
 * @todo importar somente os módulos.
 * Dentro de cada módulo um novo router.js deve importar seus submódulos
 */
