export default {
  home: {
    title: "Home"
  },
  formUser: {
    labels: {
      personalData: "Dados Pessoais",
      documents: "Documentos",
      userProfile: "Perfil de Funcionário",
      category: "Categoria",
      crc: "CRC",
      uninformed: "Não informado"
    },
    errors: {
      unableView: "Não foi possível visualizar os dados do funcionário",
      submitError: "Aconteceu algum erro ao cadastrar o funcionário"
    }
  },
  formUserCRC: {
    title: "Adicione a uma CRC",
    successMessages: {
      create: "Registro criado com sucesso!"
    }
  },
  users: {
    noHasUser: {
      title: "Você ainda não cadastrou nenhum funcionário, deseja cadastrar?",
      btnNewUser: "Cadastrar o primeiro funcionário"
    },
    hasUser: {
      header: {
        btnNewUser: "Cadastrar Funcionário",
        labelProfiles: "Todos os perfis",
        labelCRCFilter: "Todos CRC",
        labelSearchUser: "Buscar funcionário",
        btnAddUser: "Adicionar funcionário"
      },
      body: {
        labelEdit: "Editar",
        labelResendEmail: "Reenviar e-mail",
        labelBlock: "Bloquear",
        labelActive: "Ativar",
        labelUserNotFound: "Funcionário não encontrado"
      },
      modalUser: {
        labelContent:
          "Ao reenviar e-mail o funcionário poderá fazer sua autenticação e cadastrar sua senha para ter acesso a plataforma.",
        btnBack: "Voltar",
        btnCancel: "Cancelar",
        labelBlock: "Bloquear",
        labelActive: "Ativar",
        btnSaveAndSend: "Salvar e Enviar",
        btnConfirmAndSend: "Confirmar e Enviar"
      }
    },
    errors: {
      resendEmail: "O cadastro deste funcionário já foi finalizado"
    }
  }
};
