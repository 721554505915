import axios from "axios";

const pending = {};
const CancelToken = axios.CancelToken;

function isWriterMethod(method) {
  if (!method) {
    return false;
  }
  return ["post", "put", "delete", "patch"].includes(method.toLowerCase());
}

function addFlagUrlToPending(flagUrl, cancel) {
  pending[flagUrl] = cancel;

  setTimeout(
    () => delete pending[flagUrl],
    process.env.VUE_APP_DEBOUNCE_TIME_REQUEST
  );
}

function serializeConfigToFlagUrl(config) {
  const url = config.url.replace(config.baseURL, "/");
  const params = config.params ? JSON.stringify(config.params) : "";
  return `${url}&${config.method}&${params}`;
}

function debounceRequest(config, cancel) {
  if (!isWriterMethod(config.method)) {
    return;
  }

  const flagUrl = serializeConfigToFlagUrl(config);

  if (flagUrl in pending) {
    if (cancel) {
      cancel();
    }
  } else {
    addFlagUrlToPending(flagUrl, cancel);
  }
}

export function debounceRequestInterceptor(config) {
  config.cancelToken = new CancelToken(cancel => {
    debounceRequest(config, cancel);
  });

  return config;
}

export function debounceRequestErrorInterceptor(error) {
  Promise.reject(error);
}
