import HttpService from "../../../../modules/core/network/http-service";

class UserHttpService extends HttpService {
  constructor() {
    super("account/user");
  }

  createUser(payload) {
    return this.post(payload);
  }

  resendEmail({ data, id }) {
    return this.patch(data, `${id}/resend-confirmation-account`);
  }

  selectCRC(id) {
    return this.put(id, `select-crc/${id}`);
  }

  getSelectedCRC() {
    return this.get(`selected-crc`);
  }

  changeSituation({ data, id }) {
    return this.patch(data, `${id}/situation`);
  }

  getUsers(payload) {
    return this.get(payload);
  }

  getUser(id) {
    return this.get(id);
  }

  updateUser({ data, id }) {
    return this.put(data, `${id}`);
  }

  getMe() {
    return this.get("me");
  }
}

export default new UserHttpService();
