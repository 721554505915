import { loading } from "@/modules/core/helpers/loading";

import DonatorHttpService from "../DonatorHttpService";

export const addDonator = loading(async ({ commit }, payload) => {
  const response = await DonatorHttpService.addDonator(payload);
  return response.data;
});

export const getDonator = loading(async ({ commit }, payload) => {
  const response = await DonatorHttpService.getDonator(payload);
  return response.data;
});

export const getDonatorPF = loading(async ({ commit }, payload) => {
  const response = await DonatorHttpService.getDonatorPF(payload);
  return response.data;
});

export const getDonatorPJ = loading(async ({ commit }, payload) => {
  const response = await DonatorHttpService.getDonatorPJ(payload);
  return response.data;
});

export const getDetailDonatorPJ = loading(async ({ commit }, payload) => {
  const response = await DonatorHttpService.getDetailDonatorPJ(payload);
  return response.data;
});

export const getDetailDonatorPF = loading(async ({ commit }, payload) => {
  const response = await DonatorHttpService.getDetailDonatorPF(payload);
  return response.data;
});

export const updateDonatorPF = loading(async ({ commit }, payload) => {
  const { data } = await DonatorHttpService.updateDonatorPF(
    payload.id,
    payload.data
  );

  return data;
});

export const updateDonatorPJ = loading(async ({ commit }, payload) => {
  const { data } = await DonatorHttpService.updateDonatorPJ(
    payload.id,
    payload.data
  );

  return data;
});

export const getRequestDonations = loading(async ({ commit }, payload) => {
  const response = await DonatorHttpService.getRequestDonations(payload);
  return response.data;
});
