import HttpService from "@/modules/core/network/http-service";

class BatchItemService extends HttpService {
  constructor() {
    super("stock/batch/batch-item");
  }

  createBatchItem(payload) {
    return this.post(payload);
  }

  sendBatchItemsToDestiny(payload) {
    return this.patch(payload);
  }
}

export default new BatchItemService();
