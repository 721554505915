import { profiles } from "../core/enums/profiles.enum";
import { ContentLayout } from "../core/views/templates";
import BatchRoute from "@/modules/stock/batch/views/router";
import PendingRoute from "@/modules/stock/pending/views/router";
import ReconditioningRoute from "@/modules/stock/reconditioning/view/router";
import PartsInventoryRoute from "@/modules/stock/parts-inventory/view/router";
import DestinationReconditioningRoute from "@/modules/stock/destination-reconditioning/views/router";
import DestinationDemanufacturingRoute from "@/modules/stock/destination-demanufacturing/views/router";
import DestinationRoute from "@/modules/stock/destination/views/router";
import DemanufacturingRoute from "@/modules/stock/demanufacturing/views/router";

export default [
  {
    path: "/estoque",
    component: ContentLayout,
    sidebar: {
      name: "Estoque",
      icon: "mdi-widgets",
      index: 9,
      multiple: true,
      group: "/estoque/*",
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.OPERATOR_BATCH.id,
        profiles.OPERATOR_STOCK.id,
        profiles.OPERATOR_UNMANUFACTURE.id,
        profiles.OPERATOR_RECONDITION.id
      ]
    },
    children: [
      ...BatchRoute,
      ...PendingRoute,
      ...ReconditioningRoute,
      ...PartsInventoryRoute,
      ...DestinationReconditioningRoute,
      ...DestinationDemanufacturingRoute,
      ...DestinationRoute,
      ...DemanufacturingRoute
    ]
  }
];
