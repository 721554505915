import { blobToDataUrl } from "@/modules/core/helpers/fileHelpers";

import { mapActions } from "vuex";

export default {
  props: {
    value: {
      type: null
    }
  },

  data: () => ({
    blobImage: null
  }),

  watch: {
    value: {
      immediate: true,
      handler: async function() {
        this.blobImage = null;
        if (typeof this.getDefaultImage !== "function") {
          throw new Error(
            "Use of ImageMixin mixin MUST implement getDefaultImage method"
          );
        }
        await this.fetchImage();
      }
    }
  },

  computed: {
    photoSrc: {
      get() {
        const blobImage = this.blobImage ?? null;
        return blobImage ?? this.getDefaultImage();
      },
      set(newVal) {
        this.$emit("input", newVal);
      }
    }
  },

  methods: {
    ...mapActions(["getBlobFile"]),

    async fetchImage() {
      if (!this.value) {
        return;
      }

      const blob = await this.getBlobFile(this.value);

      const dataUrl = await blobToDataUrl(blob);
      this.blobImage = dataUrl;
    }
  }
};
