import { ContentLayout } from "@/modules/core/views/templates";

import { profiles } from "@/modules/core/enums/profiles.enum";

export default [
  {
    path: "/doadores",
    component: ContentLayout,
    sidebar: {
      name: "Doadores",
      icon: "mdi-hand-coin-outline",
      index: 4,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.RECEPTIONIST.id
      ]
    },
    children: [
      {
        name: "donators",
        path: "",
        meta: {
          title: "Doadores",
          public: false,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () => import("@/modules/donator/views/pages/Donators.vue")
      },
      {
        name: "new-donator",
        path: "novo-doador",
        meta: {
          title: "Cadastrar Doador",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () => import("@/modules/donator/views/pages/NewDonator.vue")
      },
      {
        name: "detail-donator-pj",
        path: "pessoa-juridica/:id(\\d+)",
        meta: {
          title: "Doador",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () =>
          import("@/modules/donator/views/pages/DetailDonatorPJ.vue")
      },
      {
        name: "detail-donator-pf",
        path: "pessoa-fisica/:id(\\d+)",
        meta: {
          title: "Doador",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () =>
          import("@/modules/donator/views/pages/DetailDonatorPF.vue")
      }
    ]
  }
];
