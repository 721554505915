import HttpService from "@/modules/core/network/http-service";
class AuthenticationHttpService extends HttpService {
  constructor() {
    super("account");
  }

  login(payload) {
    return this.post(payload, "user/authentication/login");
  }

  resetPassword(payload) {
    return this.put(payload, "reset-password");
  }

  confirmAccount(payload) {
    return this.patch(payload, "user/confirm-account");
  }

  forgotPassword(payload) {
    return this.post(payload, "forgot-password");
  }
}

export default new AuthenticationHttpService();
