import userLocales from "@/modules/account/user/locales/pt.js";
import authenticationLocales from "@/modules/account/authentication/locales/pt.js";
import crcLocales from "@/modules/crc/locales/pt.js";
import partnerLocales from "@/modules/partner/locales/pt.js";

const coreLocales = {
  root: {
    form: {
      addressLabels: {
        address: "Endereço",
        zipCode: "CEP",
        number: "Número",
        complement: "Complemento",
        neighborhood: "Bairro",
        city: "Cidade",
        state: "Estado",
        description: "Descricão"
      },
      personalLabels: {
        profile: "Perfil",
        firstName: "Nome",
        surname: "Sobrenome",
        email: "E-mail",
        birthDate: "Data de nascimento"
      },
      documentsLabels: {
        cpf: "CPF",
        rg: "RG",
        issuingBody: "Órgão expedidor",
        labelCNH: "CNH (Carteira de Habilitação Nacional)",
        cnh: "CNH",
        category: "Categoria",
        categoryCnh: "Categoria CNH"
      },
      companyLabels: {
        cnpj: "CNPJ",
        representative: "Representante",
        companyName: "Nome da empresa",
        companyInformation: "Informações da empresa",
        openingHours: "Horário de funcionamento"
      },
      crcLabels: {
        crcName: "Informações da CRC",
        crc: "CRC"
      },
      contactLabels: {
        title: "Contato",
        addPhone: "+ Adicionar",
        delPhone: "Remover",
        phone: "Telefone",
        phoneType: "Tipo de telefone",
        phoneNumber: "Número de telefone"
      },
      partnerLabels: {
        contract: "Contrato",
        startDate: "Data início",
        endDate: "Data término"
      },
      next: "Próximo",
      cancel: "Cancelar",
      uninformed: "Não informado"
    },
    errorsForm: {
      requiredInput: "O campo é obrigatório",
      invalidCpf: "CPF inválido",
      invalidEmail: "Email inválido",
      informYourCnh: "Por favor informe sua CNH"
    },
    utilsLabels: {
      search: "Buscar",
      uninformed: "Não informado",
      employees: "Funcionários"
    },
    actionButtonLabels: {
      exit: "Sair",
      send: "Confirmar e enviar",
      cancel: "Cancelar",
      save: "Salvar",
      next: "Próximo"
    }
  }
};

export default {
  ...coreLocales,
  ...crcLocales,
  ...userLocales,
  ...authenticationLocales,
  ...partnerLocales
};
