import HttpService from "@/modules/core/network/http-service";

class CollectionRequestHttpService extends HttpService {
  constructor() {
    super("collection-request");
  }

  addCollectionRequest(payload) {
    return this.post(payload);
  }

  getRequestById(id) {
    return this.get(`${id}`);
  }

  updateRequestById({ id, payload }) {
    return this.put(payload, `${id}`);
  }

  cancelCollectionRequest(payload) {
    return this.put(
      { situation: { id: 2 }, reasonSituation: payload.reasonSituation },
      `${payload.requestId}`
    );
  }

  getHistoric(payload) {
    return this.get("historic", { params: payload });
  }
}

export default new CollectionRequestHttpService();
