import HttpService from "@/modules/core/network/http-service";
class FileHttpService extends HttpService {
  constructor() {
    super("file");
  }

  uploadFile(payload) {
    return this.post(payload);
  }

  getFile(hash) {
    return this.get(`${hash}`);
  }

  getBlobFile(hash) {
    return this.get(hash, {
      responseType: "blob"
    });
  }

  removeFile(hash) {
    return this.delete(hash);
  }
}

export default new FileHttpService();
