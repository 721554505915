import { profiles } from "@/modules/core/enums/profiles.enum";

export default [
  {
    name: "destination-residues",
    path: "destinacao-residuos",
    sidebar: {
      name: "Destinação Resíduos",
      icon: null,
      index: 5,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id
      ]
    },
    meta: {
      title: "Destinação Resíduos",
      public: false,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id
      ]
    },

    component: () =>
      import(
        "@/modules/stock/destination-demanufacturing/views/pages/DestinationDemanufacturing"
      )
  }
];
