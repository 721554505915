export const getPartnerForm = state => state.partnerFormToSubmit;

export const getCreateStep = state => state.data.create.step;

export const getPevs = state => state.data.create.pevs;

export const getFormData = state => state.data.create.form;

export const getSelectedCrc = state => state.data.create.selectedCrc;

export const getPartnerView = state => state.data.view;

export const getHasPartnerVoluntaryDeliveryPoints = state =>
  !!state.data?.view?.voluntaryDeliveryPoints?.length;

export const getPartnerVoluntaryDeliveryPoints = state =>
  state.data?.view?.voluntaryDeliveryPoints;

export const getHasPartnerContracts = state =>
  state.data?.view?.contracts?.length;

export const getLastPartnerContract = state => {
  return state.data?.view?.lastContract ?? null;
};
