export function readFilenameFromContentDisposition(
  contentDisposition,
  defaultFilename = "arquivo"
) {
  const match = contentDisposition.match(
    /attachment; filename="(?<filename>.*)"/
  );

  return match?.groups?.filename || defaultFilename;
}
