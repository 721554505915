import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import CRCHttpService from "../service/CRCHttpService";

export const createCRC = loading(async ({ commit }, payload) => {
  const response = await CRCHttpService.createCRC(payload);
  return response.data;
});

export const getCRCs = loading(async ({ commit }, payload) => {
  const response = await CRCHttpService.getCRCs(payload);
  return response.data;
});

export const getCRC = loading(async ({ commit }, id) => {
  const response = await CRCHttpService.getCRC(id);
  return response.data;
});

export const getUsersFromCRC = loading(async ({ commit }, payload) => {
  const response = await CRCHttpService.getUsersFromCRC(payload);
  return response.data;
});

export const updateCRC = loading(async ({ commit }, payload) => {
  const response = await CRCHttpService.updateCRC(payload);
  return response.data;
});

export const updateManagers = loading(async ({ commit }, payload) => {
  const response = await CRCHttpService.updateManagers(payload);
  return response.data;
});

export const updateStaff = loading(async ({ commit }, payload) => {
  const response = await CRCHttpService.updateStaff(payload);
  return response.data;
});

export const toggleStatusCRC = loading(async ({ commit }, id) => {
  const response = await CRCHttpService.toggleStatusCRC(id);
  return response.data;
});

export const getAddressByZipCode = loading(async ({ commit }, zipCode) => {
  const response = await CRCHttpService.getAddressByZipCode(zipCode);
  return response.data;
});

export const toggleModalListCRC = ({ commit }) => {
  commit(MutationTypes.TOGGLE_MODAL_LIST_CRC);
};
