import HttpService from "@/modules/core/network/http-service";

class GranteeService extends HttpService {
  constructor() {
    super("grantee");
  }

  getSimplified(payload) {
    return this.get("simplified", { params: payload });
  }
}

export default new GranteeService();
