import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import PartnerHttpService from "../service/PartnerHttpService";
import PEVHttpService from "../service/PEVHttpService";

export const createPartner = loading(async ({ commit }, payload) => {
  const { data } = await PartnerHttpService.createPartner(payload);
  return data;
});

export const updatePartner = loading(async ({ commit }, payload) => {
  const data = await PartnerHttpService.update(payload.id, payload.body);
  return data;
});

export const getPevs = loading(async ({ commit }, payload) => {
  const data = await PartnerHttpService.getPevs(payload);
  return data;
});

export const getPartnerById = loading(async ({ commit }, id) => {
  const data = await PartnerHttpService.getById(id);
  return data;
});

export const createPEV = loading(async ({ commit }, payload) => {
  const data = await PartnerHttpService.addPEVToPartner(payload.body);
  return data;
});

export const updatePEV = loading(async ({ commit }, payload) => {
  const data = await PEVHttpService.update(payload.id, payload.body);
  return data;
});

export const deletePEV = loading(async ({ commit }, id) => {
  const data = await PEVHttpService.deletePEV(id);
  return data;
});

export const togglePartnerStatus = loading(async ({ commit }, id) => {
  const data = await PartnerHttpService.togglePartnerStatus(id);
  return data;
});

export const getPartnerContracts = loading(
  async ({ commit }, { id, params }) => {
    const data = await PartnerHttpService.getPartnerContracts(id, { params });
    return data;
  }
);

export const createContract = loading(async ({ commit }, payload) => {
  const data = await PartnerHttpService.createContract(
    payload.id,
    payload.data
  );
  return data;
});

export const resetCreateState = ({ commit }) => {
  commit(MutationTypes.RESET_CREATE_STATE);
};

export const setMaxSteps = ({ commit }, maxSteps) => {
  commit(MutationTypes.SET_MAX_STEPS, maxSteps);
};

export const setCurrentStep = ({ commit }, currentStep) => {
  commit(MutationTypes.SET_CURRENT_STEP, currentStep);
};

export const addPev = ({ commit }, pev) => {
  commit(MutationTypes.ADD_PEV, pev);
  commit(MutationTypes.INCREMENT_PEV_COUNT);
};

export const removePev = ({ commit }, localId) => {
  commit(MutationTypes.REMOVE_PEV, localId);
};

export const setFormData = ({ commit }, formData) => {
  commit(MutationTypes.SET_FORM_DATA, formData);
};

export const setSelectedCrc = ({ commit }, selectedCrc) => {
  commit(MutationTypes.SET_SELECTED_CRC, selectedCrc);
};

export const resetContract = ({ commit, dispatch, getters }) => {
  commit(MutationTypes.RESET_CONTRACT);
};

export const getPartners = loading(async (_, filters) =>
  PartnerHttpService.getPartners(filters)
);

export const resetPartnerView = ({ commit }) => {
  commit(MutationTypes.RESET_PARTNER_VIEW);
};

export const setPartnerView = ({ commit }, payload) => {
  commit(MutationTypes.SET_PARTNER_VIEW, payload);
};
