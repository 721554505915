<template>
  <v-dialog
    v-if="activeMode"
    v-model="dialog"
    scrollable
    :persistent="true"
    :origin="'center left'"
    :width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card class="modal">
      <v-card-title class="modal-title">{{ title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text v-show="!!message" class="modal-content">
        <p>{{ message }}</p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex flex-wrap justify-space-between">
        <v-btn
          @click.native="cancel"
          class="mt-1 cancel-button"
          :color="activeMode.cancelColor"
          :class="activeMode.cancelClass"
          :text="activeMode.cancelIsText"
          :block="$isMobile"
          >Cancelar</v-btn
        >
        <v-btn
          type="submit"
          class="ma-0 mt-1 next-step-button"
          :class="activeMode.confirmClass"
          :color="activeMode.confirmColor"
          :text="activeMode.confirmIsText"
          :block="$isMobile"
          @click.native="agree"
          >Confirmar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  /**
   * Vuetify Confirm Dialog component
   *
   * Insert component where you want to use it:
   * <confirm ref="confirm"></confirm>
   *
   * Call it:
   * this.$refs.confirm.open('Delete', 'Are you sure?').then((confirm) => {})
   * Or use await:
   * if (await this.$refs.confirm.open('Delete', 'Are you sure?')) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
   * <template>
   *   <v-app>
   *     ...
   *     <confirm ref="confirm"></confirm>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$confirm = this.$refs.confirm.open
   * }
   */
  export default {
    name: "ConfirmDialog",
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        width: 500,
        zIndex: 200
      },
      modes: {
        danger: {
          cancelColor: "error",
          cancelClass: "white--text",
          confirmColor: "",
          confirmClass: "error--text",
          cancelIsText: false,
          confirmIsText: true
        },
        alert: {
          cancelColor: "",
          cancelClass: "black-3--text",
          confirmColor: "secondary-color-1",
          confirmClass: "white--text",
          cancelIsText: true,
          confirmIsText: false
        }
      },
      activeMode: null
    }),
    methods: {
      open(title, message, options, mode = "alert") {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.activeMode = this.modes[mode];
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      agree() {
        this.resolve(true);
        this.dialog = false;
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/buttons";

  .modal {
    border-radius: 6px !important;

    .modal-title {
      font-size: 24px !important;
      font-weight: 400 !important;
      color: $black-1 !important;
      opacity: 0.9 !important;
      padding: 20px 0px 15px 25px !important;
    }
  }

  .modal-content {
    padding: 24px 20px !important;
  }
</style>
