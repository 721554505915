import { profiles } from "@/modules/core/enums/profiles.enum";
import { ContentLayout } from "@/modules/core/views/templates";

import store from "@/store";
import router from "@/router";

export default [
  {
    path: "/coletas",
    component: ContentLayout,
    sidebar: {
      name: "Coleta",
      icon: "mdi-truck",
      index: 8,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.DRIVER.id
      ]
    },
    children: [
      {
        name: "collections",
        path: "",
        meta: {
          title: "Coletas",
          public: false,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.DRIVER.id
          ]
        },
        beforeEnter: (to, from, next) => {
          const user = store.getters["user/getUser"];
          if (user.role.id === profiles.DRIVER.id) {
            return router.push({ name: "myCollections" });
          }
          return next();
        },
        component: () =>
          import("@/modules/collection/views/pages/Collections.vue")
      },
      {
        name: "myCollections",
        path: "minhas-coletas",
        meta: {
          title: "Minhas coletas",
          public: false,
          permissions: [profiles.DRIVER.id]
        },
        component: () =>
          import("@/modules/collection/views/pages/MyCollections.vue")
      },
      {
        name: "collectionDetail",
        path: "visualizar-agendamento-coleta/:id(\\d+)",
        meta: {
          title: "Detalhamento do agendamento",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id,
            profiles.DRIVER.id,
            profiles.OPERATOR_BATCH.id
          ]
        },
        component: () =>
          import("@/modules/collection/views/pages/CollectionDetail.vue")
      }
    ]
  }
];
