import * as MutationTypes from "./mutation-types";

export const mutations = {
  [MutationTypes.SET_ADDRESS](state, user) {
    state.user = user;
  },

  [MutationTypes.TOGGLE_MODAL_LIST_CRC](state) {
    state.modalListCRCActivated = !state.modalListCRCActivated;
  }
};
