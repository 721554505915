import HttpService from "@/modules/core/network/http-service";

class DonatorHttpService extends HttpService {
  constructor() {
    super("grantee");
  }

  getPF(id) {
    const url = `pf/${id}`;
    return this.get(url);
  }

  getPJ(id) {
    const url = `pj/${id}`;
    return this.get(url);
  }

  getDonations(id) {
    const url = `${id}/donate`;
    return this.get(url);
  }
}

export default new DonatorHttpService();
