<template>
  <div>
    <Dialog
      :dialogActivate="canSelectCrc"
      title="Selecione uma CRC"
      :labelButtonAction="'Selecionar CRC'"
      :functionButtonAction="updateSelectedCrc"
      contentClass="select-crc-content"
    >
      <div class="modal-content-situation">
        <v-radio-group v-model="selectedCrcId">
          <v-radio
            color="primary-color-2"
            class="pl-8 py-3 item-crc"
            v-for="crc in user.avaliableCrcs"
            :key="crc.id"
            :label="crc.description"
            :value="crc.id"
          >
            <template slot="label">
              <span
                :class="{ 'selected-crc-label': innerSelectedCrcId === crc.id }"
                >{{ crc.description }}</span
              >
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </Dialog>
  </div>
</template>

<script>
  import Dialog from "@/modules/core/views/components/Dialog";
  import { firstPageByUser } from "@/modules/core/enums/profiles.enum";

  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "SelectCrc",

    components: {
      Dialog
    },
    data: () => ({
      innerSelectedCrcId: null
    }),

    mounted() {
      this.innerSelectedCrcId = this.user.selectedCrc.id;
    },

    computed: {
      ...mapGetters({
        user: "user/getUser",
        userIsAdmin: "user/getUserIsAdmin",
        userRoleId: "user/getUserRoleId",
        selectCrcDialog: "getSelectCrcDialog"
      }),

      canSelectCrc() {
        if (this.userIsAdmin || this.user.avaliableCrcs.length <= 1) {
          return false;
        }
        return this.selectCrcDialog;
      },

      selectedCrcId: {
        get() {
          return this.user.selectedCrc.id;
        },

        set(newVal) {
          this.innerSelectedCrcId = newVal;
        }
      }
    },
    methods: {
      ...mapActions("user", ["selectCRC", "setUser"]),
      ...mapActions("authentication", ["setToken"]),
      ...mapActions([
        "toggleSnackbar",
        "goToInitialPage",
        "setSelectCrcDialog"
      ]),

      async updateSelectedCrc() {
        if (!this.innerSelectedCrcId) {
          return this.toggleSnackbar({
            text: "Selecione uma CRC",
            type: "alert"
          });
        }

        try {
          const { data } = await this.selectCRC(this.innerSelectedCrcId);

          const { user } = await this.setToken(data.token);

          this.setUser(user);

          this.toggleSnackbar({
            text: "CRC selecionada com sucesso",
            type: "success"
          });

          this.$router.push({
            name: firstPageByUser[this.userRoleId]
          });

          this.setSelectCrcDialog(false);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro ao selecionar a CRC",
            type: "error"
          });
        } finally {
          this.innerSelectedCrcId = null;
        }
      }
    }
  };
</script>

<style lang="scss">
  @import "@/assets/stylesheets/styleguide";

  .item-crc {
    &:hover {
      background-color: $white-1;
    }
  }

  .selected-crc-label {
    color: $primary-color-2;
  }

  .select-crc-content {
    padding: 0 !important;
  }
</style>
