import * as MutationTypes from "./mutation-types";

export const mutations = {
  [MutationTypes.TOGGLE_SNACKBAR](state, snackbarInfo) {
    state.snackbarInfo = snackbarInfo;
  },
  [MutationTypes.TOGGLE_SIDEBAR](state) {
    state.sidebar.isShow = !state.sidebar.isShow;
  },
  [MutationTypes.SET_LOADING](state, value) {
    state.isLoading = value;
  },
  [MutationTypes.UPDATE_TABLE_ITEMS](state, items) {
    state.itemsTable = items;
  },
  [MutationTypes.SET_SIDEBAR](state, value) {
    state.sidebar.isShow = value;
  },
  [MutationTypes.INITIALIZE_ROUTES](state, routes) {
    state.routes = routes;
  },
  [MutationTypes.SET_SELECT_CRC_DIALOG](state, crcDialog) {
    state.selectCrcDialog = crcDialog;
  }
};
