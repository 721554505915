import * as MutationTypes from "./mutation-types";

export const mutations = {
  [MutationTypes.SET_ROLE](state, role) {
    state.role = role;
  },

  [MutationTypes.RESET_ROLE](state) {
    state.role = undefined;
  }
};
