import HttpService from "@/modules/core/network/http-service";

class PartInventoryService extends HttpService {
  constructor() {
    super("stock/part-inventory");
  }

  getStockRequests(payload) {
    return this.get("stock-move", { params: payload });
  }

  patchStockRequest({ id, body }) {
    return this.patch(body, `stock-move/${id}`);
  }
}

export default new PartInventoryService();
