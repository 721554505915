import Vue from "vue";
import App from "./App.vue";
import "@/modules/core/registerServiceWorker";
import router from "./router";
import store from "./store";
import { vuetify, i18n } from "@/modules/core/plugins";
import VueMask, { VueMaskDirective } from "v-mask";
import ResponsiveMixin from "@/modules/core/mixins/responsiveMixin";
import PluralizeMixin from "@/modules/core/mixins/pluralizeMixin";
import InfiniteLoading from "vue-infinite-loading";
import { ValidationProvider, extend } from "vee-validate";
import VueHtml2Canvas from "vue-html2canvas";

Vue.use(VueHtml2Canvas);

Vue.component("ValidationProvider", ValidationProvider);

Vue.use(InfiniteLoading, {
  /* options */
  system: {
    throttleLimit: 500
  }
});

Vue.mixin(ResponsiveMixin);
Vue.mixin(PluralizeMixin);

Vue.use(VueMask);
Vue.directive("mask", VueMaskDirective);

Vue.config.productionTip = false;

Object.defineProperty(Vue.prototype, "$t", { value: i18n.messages.pt });

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
