<template>
  <div>
    <title-header></title-header>
    <slot name="info-cards"></slot>
    <v-card class="page-default-card pt-7">
      <slot name="table"></slot>
    </v-card>
  </div>
</template>

<script>
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  export default {
    components: {
      TitleHeader
    }
  };
</script>

<style lang="scss" scoped>
  .page-default-card {
    border-radius: 6px;
    box-shadow: 0px 2px 2px #00000029;
    left: 0;
    transition: left 0.4s !important;
    -webkit-transition: left 0.4s !important;
  }
</style>
