import HttpService from "@/modules/core/network/http-service";

class CollectionScheduleHttpService extends HttpService {
  constructor() {
    super("collection-schedule");
  }

  getCollection(payload) {
    return this.get(payload);
  }

  removeCollectionRequestFromSchedule(payload) {
    return this.delete(
      `${payload.scheduleId}/collection-request/${payload.requestId}`
    );
  }

  cancelCollectionSchedule(payload) {
    return this.delete(`${payload.scheduleId}`);
  }

  getScheduleDetail(payload) {
    return this.get(payload);
  }

  updateScheduleById({ id, payload }) {
    return this.put(payload, `${id}`);
  }

  addCollection(payload) {
    return this.post(payload);
  }

  changeSituationRequest({ id, payload }) {
    return this.patch(payload, `collection-request/${id}/situation`);
  }
}

export default new CollectionScheduleHttpService();
