import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";

import DemanufacturingService from "@/modules/stock/demanufacturing/services/DemanufacturingService";

export const getDemanufacturingBatchs = loading(async ({ commit }, payload) => {
  const { data } = await DemanufacturingService.get(payload);

  return data;
});

export const getDemanufacturingById = loading(async ({ commit }, id) => {
  const { data } = await DemanufacturingService.get(id);

  return data;
});

export const createDemanufacturingResidueSubtype = loading(
  async ({ commit }, { id, body }) => {
    const { data } = await DemanufacturingService.post(body, id);

    return data;
  }
);

export const sendResidueStock = loading(async (_param, { id, body }) => {
  const { data } = await DemanufacturingService.sendResidueStock({ id, body });

  return data;
});

export const concludeDemanufacturing = loading(async (_param, id) => {
  const { data } = await DemanufacturingService.conclude(id);

  return data;
});
