import * as MutationTypes from "./mutation-types";
import { createInitialState, createDocumentsInitialState } from "./state";

export const mutations = {
  [MutationTypes.RESET_CREATE_STATE](state) {
    state.data = {
      ...state.data,
      create: createInitialState()
    };
  },

  [MutationTypes.SET_CURRENT_STEP](state, currentStep) {
    state.data = {
      ...state.data,
      create: {
        ...state.data.create,
        step: {
          ...state.data.create.step,
          currentStep: currentStep
        }
      }
    };
  },

  [MutationTypes.SET_FORM_DATA](state, formData) {
    state.data = {
      ...state.data,
      create: {
        ...state.data.create,
        form: {
          ...state.data.create.form,
          ...formData,
          phones: [...formData.phones],
          address: {
            ...formData.address,
            state: {
              ...formData.address.state
            }
          }
        }
      }
    };
  },

  [MutationTypes.SET_MAX_STEPS](state, maxSteps) {
    state.data.create.step = {
      ...state.data.create.step,
      maxSteps
    };
  },

  [MutationTypes.RESET_CREATE_DOCUMENTS](state) {
    state.data = {
      ...state.data,
      createDocuments: createDocumentsInitialState()
    };
  },

  [MutationTypes.SET_CREATE_DOCUMENTS_RECYCLER_ID](state, id) {
    state.data = {
      ...state.data,
      createDocuments: {
        ...state.data.createDocuments,
        recyclerId: id
      }
    };
  },

  [MutationTypes.SET_CREATE_DOCUMENTS_ITEMS](state, items) {
    state.data = {
      ...state.data,
      createDocuments: {
        ...state.data.createDocuments,
        documents: [...items]
      }
    };
  }
};
