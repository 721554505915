import HttpService from "@/modules/core/network/http-service";

class RecyclerHttpService extends HttpService {
  constructor() {
    super("recycler");
  }

  createRecycler(payload) {
    return this.post(payload);
  }

  getRecyclerDocumentType(id) {
    return this.get(`${id}/document-type`);
  }

  getRecyclers(payload) {
    return this.get(payload);
  }

  addDocumentRecycler({ id, payload }) {
    return this.put(payload, `${id}/documents`);
  }

  toggleRecyclerStatus(id) {
    return this.patch(null, `${id}/toggle-status`);
  }

  getRecycler(id) {
    return this.get(id);
  }

  getRecyclerDocument(id, params) {
    return this.get(`${id}/document`, { params });
  }

  async updateRecycler(id, payload) {
    const { data } = await this.patch(payload, `${id}`);
    return data;
  }
}

export default new RecyclerHttpService();
