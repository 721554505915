import { loading } from "@/modules/core/helpers/loading";

import DonatorHttpService from "../services/GranteeHttpService";
import RecyclerHttpService from "../services/RecyclerHttpService";

export const getGrantees = loading(async ({ commit }, payload) => {
  const response = await DonatorHttpService.get(payload);
  return response.data;
});

export const getGranteePF = loading(async ({ commit }, id) => {
  const response = await DonatorHttpService.getPF(id);
  return response.data;
});

export const getGranteePJ = loading(async ({ commit }, id) => {
  const response = await DonatorHttpService.getPJ(id);
  return response.data;
});

export const getDonations = loading(async ({ commit }, payload) => {
  const response = await DonatorHttpService.getDonations(payload);
  return response.data;
});

export const getRecyclers = loading(async ({ commit }, payload) => {
  const response = await RecyclerHttpService.getRecyclers(payload);
  return response.data;
});

export const getRecycler = loading(async ({ commit }, id) => {
  const response = await RecyclerHttpService.get(id);
  return response.data;
});

export const getDestinations = loading(async ({ commit }, payload) => {
  const { id, data } = payload;
  const response = await RecyclerHttpService.getDestinations(id, data);
  return response.data;
});
