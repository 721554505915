export const state = {
  states: {
    Acre: {
      id: 1,
      description: "Acre",
      uf: "AC"
    },

    Alagoas: {
      id: 2,
      description: "Alagoas",
      uf: "AL"
    },

    Amapá: {
      id: 3,
      description: "Amapá",
      uf: "AP"
    },

    Amazonas: {
      id: 4,
      description: "Amazonas",
      uf: "AM"
    },

    Bahia: {
      id: 5,
      description: "Bahia",
      uf: "BA"
    },

    Ceará: {
      id: 6,
      description: "Ceará",
      uf: "CE"
    },

    "Distrito Federal": {
      id: 7,
      description: "Distrito Federal",
      uf: "DF"
    },

    "Espírito Santo": {
      id: 8,
      description: "Espírito Santo",
      uf: "ES"
    },

    Goiás: {
      id: 9,
      description: "Goiás",
      uf: "GO"
    },

    Maranhão: {
      id: 10,
      description: "Maranhão",
      uf: "MA"
    },

    "Mato Grosso": {
      id: 11,
      description: "Mato Grosso",
      uf: "MT"
    },

    "Mato Grosso do Sul": {
      id: 12,
      description: "Mato Grosso do Sul",
      uf: "MS"
    },

    "Minas Gerais": {
      id: 13,
      description: "Minas Gerais",
      uf: "MG"
    },

    Pará: {
      id: 14,
      description: "Pará",
      uf: "PA"
    },

    Paraíba: {
      id: 15,
      description: "Paraíba",
      uf: "PB"
    },

    Paraná: {
      id: 16,
      description: "Paraná",
      uf: "PR"
    },

    Pernambuco: {
      id: 17,
      description: "Pernambuco",
      uf: "PE"
    },

    Piauí: {
      id: 18,
      description: "Piauí",
      uf: "PI"
    },

    "Rio de Janeiro": {
      id: 19,
      description: "Rio de Janeiro",
      uf: "RJ"
    },

    "Rio Grande do Norte": {
      id: 20,
      description: "Rio Grande do Norte",
      uf: "RN"
    },

    "Rio Grande do Sul": {
      id: 21,
      description: "Rio Grande do Sul",
      uf: "RS"
    },

    Rondônia: {
      id: 22,
      description: "Rondônia",
      uf: "RO"
    },

    Roraima: {
      id: 23,
      description: "Roraima",
      uf: "RR"
    },

    "Santa Catarina": {
      id: 24,
      description: "Santa Catarina",
      uf: "SC"
    },

    "São Paulo": {
      id: 25,
      description: "São Paulo",
      uf: "SP"
    },

    Sergipe: {
      id: 26,
      description: "Sergipe",
      uf: "SE"
    },

    Tocantins: {
      id: 27,
      description: "Tocantins",
      uf: "TO"
    }
  },
  modalListCRCActivated: false
};
