import { loading } from "@/modules/core/helpers/loading";
import AuthenticacionService from "../service/AuthenticationHttpService";
import JWTService from "@/modules/core/service/JWTService";

export const login = loading(async ({ commit }, payload) => {
  const response = await AuthenticacionService.login(payload);
  return response.data;
});

export const resetPassword = loading(async ({ commit }, payload) => {
  const response = await AuthenticacionService.resetPassword(payload);
  return response.data;
});

export const forgotPassword = loading(async ({ commit }, payload) => {
  const response = await AuthenticacionService.forgotPassword(payload);
  return response.data;
});

export const confirmAccount = loading(async ({ commit }, payload) => {
  const response = await AuthenticacionService.confirmAccount(payload);
  return response.data;
});

export const logoutUser = ({ commit }) => {
  JWTService.logout();
};

export const setToken = ({ commit }, token) => {
  return JWTService.storeToken(token);
};
