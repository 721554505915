import { profiles } from "@/modules/core/enums/profiles.enum";

export default [
  {
    name: "destination-reconditioning",
    path: "destinacao-recondicionados",
    sidebar: {
      name: "Destinação Recondicionados",
      icon: null,
      index: 5,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id
      ]
    },
    meta: {
      title: "Destinação Recondicionados",
      public: false,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id
      ]
    },
    component: () =>
      import(
        "@/modules/stock/destination-reconditioning/views/pages/DestinationReconditioning"
      )
  }
];
