import { loading } from "@/modules/core/helpers/loading";
import AgendaHttpService from "../service/AgendaHttpService";
import DonatorHttpService from "../service/DonatorHttpService";
import CollectionScheduleHttpService from "../service/CollectionScheduleHttpService";
import CollectionRequestHttpService from "../service/CollectionRequestHttpService";
import CollectionRequestCityHttpService from "../service/CollectionRequestCityHttpService";

export const getCollection = loading(async ({ commit }, payload) => {
  const response = await AgendaHttpService.getCollection(payload);
  return response.data;
});

export const getCollectionRequestHistoric = loading(
  async ({ commit }, payload) => {
    const response = await CollectionRequestHttpService.getHistoric(payload);
    return response.data;
  }
);

export const addCollectionRequest = loading(async ({ commit }, payload) => {
  const response = await CollectionRequestHttpService.addCollectionRequest(
    payload
  );
  return response.data;
});

export const getRequestById = loading(async ({ commit }, id) => {
  const response = await CollectionRequestHttpService.getRequestById(id);
  return response.data;
});

export const updateRequestById = loading(async ({ commit }, payload) => {
  const response = await CollectionRequestHttpService.updateRequestById(
    payload
  );
  return response.data;
});

export const getCollectionSchedule = loading(async ({ commit }, payload) => {
  const response = await CollectionScheduleHttpService.getCollection(payload);
  return response.data;
});

export const removeCollectionRequestFromSchedule = loading(
  async ({ commit }, payload) => {
    const response = await CollectionScheduleHttpService.removeCollectionRequestFromSchedule(
      payload
    );
    return response.data;
  }
);

export const cancelCollectionRequest = loading(async ({ commit }, payload) => {
  const response = await CollectionRequestHttpService.cancelCollectionRequest(
    payload
  );
  return response.data;
});

export const getCitys = loading(async ({ commit }, payload) => {
  const response = await CollectionRequestCityHttpService.getCitys();
  return response.data;
});

export const deleteCollectionSchedule = loading(async ({ commit }, payload) => {
  const response = await CollectionScheduleHttpService.cancelCollectionSchedule(
    payload
  );
  return response.data;
});

export const addCollectionSchedule = loading(async ({ commit }, payload) => {
  const response = await CollectionScheduleHttpService.addCollection(payload);
  return response.data;
});

export const getScheduleDetail = loading(async ({ commit }, payload) => {
  const response = await CollectionScheduleHttpService.getScheduleDetail(
    payload
  );
  return response.data;
});

export const updateScheduleById = loading(async ({ commit }, payload) => {
  const response = await CollectionScheduleHttpService.updateScheduleById(
    payload
  );
  return response.data;
});

export const changeSituationRequest = loading(async ({ commit }, payload) => {
  const response = await CollectionScheduleHttpService.changeSituationRequest(
    payload
  );
  return response.data;
});
