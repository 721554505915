import HttpService from "@/modules/core/network/http-service";

class PEVHttpService extends HttpService {
  constructor() {
    super("partner/voluntary-delivery-point");
  }

  async create(payload) {
    const { data } = await this.post(payload);
    return data;
  }

  async update(id, payload) {
    const { data } = await this.patch(payload, `${id}`);
    return data;
  }

  deletePEV(id) {
    return this.delete(`${id}`);
  }
}

export default new PEVHttpService();
