export const createDocumentsInitialState = () => {
  return {
    recyclerId: undefined,
    documents: []
  };
};

export const createInitialState = () => {
  return {
    step: {
      currentStep: 1,
      maxSteps: undefined
    },
    form: {
      name: undefined,
      cnpj: undefined,
      site: undefined,
      email: undefined,
      phones: [{}],
      recyclerAgent: undefined,
      isRegular: undefined,
      removedAt: undefined,
      residueSubtypes: [],
      address: {
        zipCode: undefined,
        address: undefined,
        number: undefined,
        complement: undefined,
        neighborhood: undefined,
        city: undefined,
        state: {}
      },
      exporter: undefined
    }
  };
};

export const initialState = () => {
  return {
    create: createInitialState(),
    createDocuments: createDocumentsInitialState()
  };
};

export const state = {
  data: initialState()
};
