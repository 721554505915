export const RESET_CREATE_STATE = "RESET_CREATE_STATE";
export const SET_MAX_STEPS = "SET_MAX_STEPS";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const ADD_PEV = "ADD_PEV";
export const REMOVE_PEV = "REMOVE_PEV";
export const INCREMENT_PEV_COUNT = "INCREMENT_PEV_COUNT";
export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_SELECTED_CRC = "SET_SELECTED_CRC";
export const RESET_CONTRACT = "RESET_CONTRACT";
export const RESET_PARTNER_VIEW = "RESET_PARTNER_VIEW";
export const SET_PARTNER_VIEW = "SET_PARTNER_VIEW";
