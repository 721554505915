import HttpService from "@/modules/core/network/http-service";

class DonatorHttpService extends HttpService {
  constructor() {
    super("recycler");
  }

  getRecyclers(payload) {
    const url = `destination`;
    return this.get(url, { params: payload });
  }

  getDestinations(id, payload) {
    const url = `destination/${id}/residue`;
    return this.get(url, { params: payload });
  }
}

export default new DonatorHttpService();
