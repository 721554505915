import { profiles } from "@/modules/core/enums/profiles.enum";

export default [
  {
    name: "destination",
    path: "destinacoes",
    sidebar: {
      name: "Destinações",
      icon: null,
      index: 5,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id
      ]
    },
    meta: {
      title: "Destinações",
      public: false,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id
      ]
    },
    component: () =>
      import("@/modules/stock/destination/views/pages/Destinations")
  },
  {
    name: "detail-grantee-pf",
    path: "destinacoes/recondicionados/pessoa-fisica/:id(\\d+)",
    meta: {
      title: "Detalhar Donatário",
      public: false,
      iconBack: true,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id
      ]
    },
    component: () =>
      import("@/modules/stock/destination/views/pages/DetailGranteePF")
  },
  {
    name: "detail-grantee-pj",
    path: "destinacoes/recondicionados/pessoa-juridica/:id(\\d+)",
    meta: {
      title: "Detalhar Donatário",
      public: false,
      iconBack: true,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id
      ]
    },
    component: () =>
      import("@/modules/stock/destination/views/pages/DetailGranteePJ")
  },
  {
    name: "detail-recycler",
    path: "destinacoes/residuos/:id(\\d+)",
    meta: {
      title: "Detalhar Reciclador",
      public: false,
      iconBack: true,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id
      ]
    },
    component: () =>
      import("@/modules/stock/destination/views/pages/DetailRecycler")
  }
];
