import HttpService from "@/modules/core/network/http-service";

class BrandService extends HttpService {
  constructor() {
    super("stock/equipment/brand");
  }

  getBrands(payload) {
    return this.get(payload);
  }
}

export default new BrandService();
