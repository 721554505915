import { adminProfiles, profiles } from "@/modules/core/enums/profiles.enum";

export const getUser = state => state.data;
export const getUserRoleId = state => state.data?.role?.id;
export const getSelectedUserCRC = state => state.data?.selectedCrc;
export const getUserIsAdmin = state => {
  return adminProfiles.some(profile => profile.id === state.data.role.id);
};
export const getUserIsManagerOperator = state => {
  return state.data.role.id === profiles.MANAGER_OPERATING.id;
};
export const getUserIsPublicRelationsDirector = state => {
  return state.data.role.id === profiles.PUBLIC_RELATIONS_DIRECTOR.id;
};
export const getUserIsRecepcionist = state => {
  return state.data.role.id === profiles.RECEPTIONIST.id;
};
