import * as MutationTypes from "./mutation-types";
import { changeSituationInitialState } from "./state";

export const mutations = {
  [MutationTypes.RESET_CHANGE_SITUATION](state) {
    state.data = {
      changeSituation: changeSituationInitialState()
    };
  },

  [MutationTypes.SET_CHANGE_SITUATION_DATA](state, payload) {
    state.data = {
      changeSituation: {
        ...state.data.changeSituation,
        ...payload
      }
    };
  }
};
