export const profiles = {
  PRESIDENT: {
    description: "Presidente",
    id: 1
  },
  DIRECTOR: {
    description: "Diretor",
    id: 2
  },
  PUBLIC_RELATIONS_DIRECTOR: {
    description: "Diretor de relações públicas",
    id: 3
  },
  MANAGER_OPERATING: {
    description: "Gerente de operacional",
    id: 4
  },
  RECEPTIONIST: {
    description: "Recepcionista",
    id: 5
  },
  DRIVER: {
    description: "Motorista",
    id: 6
  },
  OPERATOR_BATCH: {
    description: "Operador de Triagem",
    id: 7
  },
  OPERATOR_STOCK: {
    description: "Operador de Estoque",
    id: 8
  },
  OPERATOR_UNMANUFACTURE: {
    description: "Operador de Desmanufatura",
    id: 9
  },
  OPERATOR_RECONDITION: {
    description: "Operador de Recondicionamento",
    id: 10
  }
};

export const firstPageByUser = {
  [profiles.PRESIDENT.id]: "panel",
  [profiles.DIRECTOR.id]: "panel",
  [profiles.PUBLIC_RELATIONS_DIRECTOR.id]: "panel",
  [profiles.MANAGER_OPERATING.id]: "crcs",
  [profiles.RECEPTIONIST.id]: "crcs",
  [profiles.DRIVER.id]: "crcs",
  [profiles.OPERATOR_BATCH.id]: "crcs",
  [profiles.OPERATOR_STOCK.id]: "crcs",
  [profiles.OPERATOR_RECONDITION.id]: "crcs",
  [profiles.OPERATOR_UNMANUFACTURE.id]: "crcs"
};

export const adminProfiles = [
  profiles.PRESIDENT,
  profiles.DIRECTOR,
  profiles.PUBLIC_RELATIONS_DIRECTOR
];

export const commonProfiles = [
  profiles.MANAGER_OPERATING,
  profiles.RECEPTIONIST,
  profiles.DRIVER,
  profiles.OPERATOR_BATCH,
  profiles.OPERATOR_STOCK,
  profiles.OPERATOR_RECONDITION,
  profiles.OPERATOR_UNMANUFACTURE
];
