import { profiles } from "@/modules/core/enums/profiles.enum";

export default [
  {
    name: "screening",
    path: "lote",
    sidebar: {
      name: "Lote",
      icon: null,
      index: 1,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.OPERATOR_BATCH.id
      ]
    },
    meta: {
      title: "Lote",
      public: false,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.OPERATOR_BATCH.id
      ]
    },
    component: () =>
      import("@/modules/stock/batch/views/pages/ListScreenings.vue")
  },
  {
    name: "new-screening",
    path: "lote/nova-lote",
    meta: {
      title: "Novo Lote",
      public: false,
      iconBack: true,
      permissions: [profiles.OPERATOR_BATCH.id]
    },
    component: () =>
      import("@/modules/stock/batch/views/pages/RegisterScreening.vue")
  },
  {
    name: "view-screening",
    path: "lote/visualizar-lote/:id(\\d+)",
    meta: {
      title: "Registro Lote",
      public: false,
      iconBack: true,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.OPERATOR_BATCH.id
      ]
    },
    component: () =>
      import("@/modules/stock/batch/views/pages/ViewScreening.vue")
  }
];
