import { profiles } from "@/modules/core/enums/profiles.enum";

export default [
  {
    name: "pending",
    path: "pendentes",
    sidebar: {
      name: "Pendentes",
      icon: null,
      index: 2,
      permissions: [profiles.OPERATOR_STOCK.id]
    },
    meta: {
      title: "Pendentes",
      public: false,
      permissions: [profiles.OPERATOR_STOCK.id]
    },
    component: () =>
      import("@/modules/stock/pending/views/pages/ListPending.vue")
  }
];
