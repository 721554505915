const { default: HttpService } = require("@/modules/core/network/http-service");

class PendingService extends HttpService {
  constructor() {
    super("stock/pending");
  }

  forwardDesignedArea(payload) {
    return this.patch(payload, "forward-designated-area");
  }
}

export default new PendingService();
