export const changeSituationInitialState = () => {
  return {
    situationId: undefined,
    batchItemId: undefined,
    amount: undefined
  };
};

export const state = {
  data: {
    changeSituation: changeSituationInitialState()
  }
};
