export const state = {
  isLoading: false,
  snackbarInfo: {
    snackbar: false,
    type: "",
    text: ""
  },
  sidebar: {
    isShow: false
  },
  itemsTable: [],
  routes: [],
  selectCrcDialog: false
};
