import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";

import PartInventoryService from "@/modules/stock/parts-inventory/services/PartInventoryService";

export const getPartsFromInventory = loading(async ({ commit }, payload) => {
  const { data } = await PartInventoryService.get(payload);

  return data;
});

export const getStockRequests = loading(async ({ commit }, payload) => {
  const { data } = await PartInventoryService.getStockRequests(payload);

  return data;
});

export const patchStockRequest = loading(async ({ commit }, payload) => {
  const { data } = await PartInventoryService.patchStockRequest(payload);

  return data;
});
