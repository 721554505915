<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogActivate"
      scrollable
      :persistent="true"
      :origin="'center left'"
    >
      <v-card class="modal">
        <v-card-title class="modal-title">{{ title }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="modal-content" :class="contentClass">
          <slot />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          class="d-flex justify-center"
          :class="{ 'justify-space-between': labelButtonCancel }"
        >
          <v-btn
            v-if="labelButtonCancel"
            @click="functionButtonCancel()"
            color="#FFF"
            class="cancel-button black-3--text"
            >{{ labelButtonCancel }}</v-btn
          >
          <v-btn
            type="submit"
            color="secondary-color-1"
            class="next-step-button white--text"
            :disabled="disabledButtonAction"
            @click="functionButtonAction()"
            >{{ labelButtonAction }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: "Dialog",

    props: {
      dialogActivate: {
        type: Boolean,
        required: true,
        default: () => false
      },
      title: {
        type: String,
        required: true,
        default: () => ""
      },
      labelButtonCancel: {
        type: String,
        required: false,
        default: () => ""
      },
      functionButtonCancel: {
        type: Function,
        required: false,
        default: () => new Function()
      },
      labelButtonAction: {
        type: String,
        required: true,
        default: () => ""
      },
      functionButtonAction: {
        type: Function,
        required: true,
        default: () => new Function()
      },
      disabledButtonAction: {
        type: Boolean,
        default: () => false
      },
      contentClass: {
        type: String,
        required: false,
        default: () => ""
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/buttons";

  .modal {
    border-radius: 6px !important;

    .modal-title {
      font-size: 24px !important;
      font-weight: 400 !important;
      color: $black-1 !important;
      opacity: 0.9 !important;
      padding: 20px 0px 15px 25px !important;
    }
  }

  ::v-deep .v-dialog.v-dialog--active.v-dialog--scrollable {
    width: 100% !important;
    max-width: fit-content !important;
    min-width: 488px !important;
    overflow: hidden;
  }
</style>
