<template>
  <div class="img-container" :class="classes">
    <v-img class="img-profile" :src="photoSrc"></v-img>
    <v-btn
      v-if="canEdit"
      id="pick-avatar"
      color="primary-color-2"
      class="ml-n8 icon-upload-photo"
      fab
      dark
      small
      ><v-icon>mdi-camera</v-icon></v-btn
    >
    <avatar-cropper
      v-if="canEdit"
      @completed="handleCompleted"
      @error="handleError"
      trigger="#pick-avatar"
      :upload-url="uploadUrl"
      :upload-form-data="{ type: 1 }"
      :upload-headers="uploadHeaders"
      :labels="{ submit: 'Salvar foto', cancel: 'Cancelar' }"
    />
  </div>
</template>
<script>
  import ImageMixin from "@/modules/core/mixins/imageMixin";

  import AvatarCropper from "vue-avatar-cropper";
  import image from "@/assets/Avatar_.png";

  import { mapActions } from "vuex";
  import JWTService from "@/modules/core/service/JWTService";

  export default {
    name: "UserPhoto",

    props: {
      value: {
        type: null
      },
      uploadUrl: {
        type: String,
        required: false,
        default: () => ""
      },
      canEdit: {
        type: Boolean,
        required: false,
        default: () => false
      },
      size: {
        type: String,
        required: false,
        default: () => "lg"
      },
      border: {
        type: String,
        required: false,
        default: () => "large"
      }
    },

    components: {
      AvatarCropper
    },

    mixins: [ImageMixin],

    computed: {
      classes() {
        return {
          ["img-container-lg"]: this.size === "lg",
          ["img-container-md"]: this.size === "md",
          ["img-container-sm"]: this.size === "sm",
          ["img-container-border-none"]: this.border === "none",
          ["img-container-border-thin"]: this.border === "thin",
          ["img-container-border-large"]: this.border === "large"
        };
      },

      uploadHeaders() {
        const token = JWTService.getToken();
        return {
          Authorization: `Bearer ${token}`
        };
      }
    },
    methods: {
      ...mapActions(["toggleSnackbar", "initializeRoutes"]),

      async handleCompleted({ data }, form, xhr) {
        if (data) {
          this.$emit("submit", { ...data });
        }
      },

      handleError() {
        this.toggleSnackbar({
          text: "Aconteceu algum erro ao salvar a foto de perfil",
          type: "error"
        });
      },

      getDefaultImage() {
        return image;
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/components";

  @include img-profile();
</style>
