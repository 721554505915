import { profiles } from "@/modules/core/enums/profiles.enum";

export default [
  {
    name: "reconditioning",
    path: "recondicionamento",
    sidebar: {
      name: "Recondicionamento",
      icon: null,
      index: 3,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.OPERATOR_RECONDITION.id
      ]
    },
    meta: {
      title: "Recondicionamento",
      public: false,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.OPERATOR_RECONDITION.id
      ]
    },
    component: () =>
      import("@/modules/stock/reconditioning/view/pages/ListReconditioning.vue")
  },
  {
    name: "reconditioning-require-parts",
    path: "reconditioning/require-parts/:id(\\d+)",
    meta: {
      title: "Requisição de Componentes",
      public: false,
      iconBack: true,
      permissions: [
        profiles.MANAGER_OPERATING.id,
        profiles.OPERATOR_RECONDITION.id
      ]
    },
    component: () =>
      import("@/modules/stock/reconditioning/view/pages/RequireParts")
  }
];
