import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import FileHttpService from "../service/FileHttpService";
import router from "@/router";

export const uploadFile = loading(async ({ commit }, payload) => {
  const response = await FileHttpService.uploadFile(payload);
  return response.data;
});

export const getFile = loading(async ({ commit }, payload) => {
  const response = await FileHttpService.getFile(payload);
  return response.data;
});

export const getBlobFile = loading(async ({ commit }, hash) => {
  const { data } = await FileHttpService.getBlobFile(hash);
  return data;
});

export const removeFile = async ({ commit }, hash) => {
  const { data } = await FileHttpService.removeFile(hash);
  return data;
};

export const toggleSnackbar = ({ commit }, snackbarInfo) => {
  snackbarInfo.snackbar = true;
  commit(MutationTypes.TOGGLE_SNACKBAR, snackbarInfo);
  setTimeout(() => {
    snackbarInfo.snackbar = false;
    commit(MutationTypes.TOGGLE_SNACKBAR, snackbarInfo);
  }, 8000);
};

export const toggleSidebar = ({ commit }) => {
  commit(MutationTypes.TOGGLE_SIDEBAR);
};

export const setSidebar = ({ commit }, value) => {
  commit(MutationTypes.SET_SIDEBAR, value);
};

export const setLoading = ({ commit }, value) => {
  commit(MutationTypes.SET_LOADING, value);
};

export const updateTableItems = ({ commit }, itemsTable) => {
  commit(MutationTypes.UPDATE_TABLE_ITEMS, itemsTable);
};
export const initializeRoutes = ({ commit }) => {
  const routes = router.options.routes
    .filter(route => !!route.sidebar)
    .map(route => route.children)
    .flat();

  commit(MutationTypes.INITIALIZE_ROUTES, routes);
};

export const setSelectCrcDialog = ({ commit }, payload) => {
  commit(MutationTypes.SET_SELECT_CRC_DIALOG, payload);
};
