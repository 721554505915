import * as MutationTypes from "./mutation-types";
import { createInitialState } from "./state";

export const mutations = {
  [MutationTypes.RESET_CREATE_STATE](state) {
    state.data = {
      ...state.data,
      create: {
        ...createInitialState(),
        key: state.data.create.key
      }
    };
  },

  [MutationTypes.SET_CURRENT_STEP](state, currentStep) {
    state.data = {
      ...state.data,
      create: {
        ...state.data.create,
        stepper: {
          currentStep: currentStep
        }
      }
    };
  },

  [MutationTypes.SET_FORM_DATA](state, formData) {
    state.data = {
      ...state.data,
      create: {
        ...state.data.create,
        form: {
          ...state.data.create.form,
          ...formData
        }
      }
    };
  },

  [MutationTypes.INCREMENT_CREATE_KEY](state) {
    state.data = {
      ...state.data,
      create: {
        ...state.data.create,
        key: state.data.create.key + 1
      }
    };
  }
};
