<template>
  <div style="height: 100%">
    <div
      class="page-default-sidebar"
      :style="{ width: sidebarIsShow ? '100%' : '0%' }"
    >
      <Sidebar />
    </div>
    <div>
      <div v-if="!isViewCRC">
        <page-header></page-header>
        <div class="container-div" :class="{ sidebarIsShow }">
          <router-view />
        </div>
      </div>
      <div v-if="isViewCRC">
        <page-header></page-header>
        <div class="container-div" :class="{ sidebarIsShow }">
          <div class="header-cards">
            <div class="title">
              <h2 class="page-title">
                <v-icon
                  @click="$router.back()"
                  v-if="$route.meta.iconBack"
                  class="icon-back"
                  >mdi-arrow-left</v-icon
                >
                {{
                  crc
                    ? crc.description
                    : "CRC - Centro de Recondicionamento de Computadores"
                }}
              </h2>
              <h3 class="page-subtitle">{{ $route.meta.subtitle }}</h3>
              <slot name="actionBtn"></slot>
            </div>
            <v-btn
              @click="goToAddUserInCRC"
              type="submit"
              color="#3CA897"
              large
              class="action-button"
              >{{ "Gerenciar funcionários" }}</v-btn
            >
          </div>
          <card v-if="isViewCRC" class="components-container">
            <template v-slot:table>
              <div class="container-card">
                <div class="container-first-component">
                  <router-view />
                </div>
                <v-card class="container-second-component">
                  <router-view name="second-component" />
                </v-card>
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
    <Dialog
      :dialogActivate="modalListCRCActivated"
      :title="'Selecione uma CRC'"
      :labelButtonCancel="
        selectedUserCRC ? $t.users.hasUser.modalUser.btnCancel : ''
      "
      :functionButtonCancel="toggleModalListCRC"
      :labelButtonAction="'Ativar CRC'"
      :functionButtonAction="updateCRC"
    >
      <div class="modal-content-situation">
        <v-list>
          <v-list-item-group mandatory color="#ea5726">
            <v-list-item
              v-for="role in currentUserRole
                ? currentUserRole.filter(el => el.crc)
                : []"
              :key="role.id"
              @click="selectedCRC = role.crc"
            >
              <v-list-item-content v-if="role.crc">
                <v-list-item-title
                  v-text="role.crc.description"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </Dialog>
    <Snackbar />
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  import Card from "@/modules/core/views/templates/CardLayout";
  import PageHeader from "@/modules/core/views/templates/DefaultLayaut/PageHeader";
  import Sidebar from "@/modules/core/views/components/Sidebar";
  import Snackbar from "@/modules/core/views/components/Snackbar";
  import Loading from "@/modules/core/views/components/Loading";
  import Dialog from "@/modules/core/views/components/Dialog";

  export default {
    components: {
      PageHeader,
      Snackbar,
      Sidebar,
      Loading,
      Dialog,
      Card
    },

    computed: {
      ...mapGetters({
        sidebarIsShow: "sidebarIsShow",
        modalListCRCActivated: "crc/getModalListCRCActivated",
        currentUserRole: "role/getRole",
        selectedUserCRC: "user/getSelectedUserCRC",
        isLoading: "isLoading"
      })
    },

    data: () => ({
      isViewCRC: false,
      crc: null,
      dialog: true,
      selectedItem: 0,
      selectedCRC: null
    }),

    watch: {
      async $route(to, from) {
        this.isViewCRC = this.$route.name === "ViewCRC" ? true : false;
        await this.setCRC();
      }
    },

    async mounted() {
      if (this.$route.name === "ViewCRC") {
        this.isViewCRC = true;
      }
      if (this.$route.params.id && this.isViewCRC) {
        await this.setCRC();
      }
      this.setSelectedItem();
    },

    methods: {
      ...mapActions("authentication", ["setToken"]),
      ...mapActions("role", ["setRole"]),
      ...mapActions("crc", ["toggleModalListCRC", "getCRC"]),
      ...mapActions("user", ["selectCRC", "setUser", "getUserSelectedCRC"]),
      ...mapActions(["toggleSnackbar"]),

      goToAddUserInCRC() {
        this.$router.push({
          name: "AddEmployee",
          params: { id: this.$route.params.id }
        });
      },

      async setCRC() {
        try {
          let response;
          if (this.selectedUserCRC) {
            response = await this.getUserSelectedCRC();
          } else {
            if (this.isViewCRC) {
              response = await this.getCRC(this.$route.params.id);
            }
          }
          this.crc = this.isViewCRC ? response.data : null;
        } catch (err) {
          console.log(err);
        }
      },

      setSelectedItem() {
        if (this.currentUserRole) {
          this.currentUserRole.forEach((role, index) => {
            if (this.selectedUserCRC) {
              if (role.crc) {
                if (role.crc.id === this.selectedUserCRC.id) {
                  this.selectedItem = index;
                }
              }
            } else {
              this.selectedItem = 0;
            }
          });
        }
      },

      async updateCRC() {
        try {
          const { data } = await this.selectCRC(
            this.selectedCRC
              ? this.selectedCRC.id
              : this.currentUserRole.filter(el => el.crc)[0].crc.id
          );
          const { token } = data;
          const { user } = await this.setToken(token);
          this.setUser(user);
          this.setRole(user.userRoles);
          this.toggleModalListCRC();
          this.toggleSnackbar({
            text: "CRC alterada com sucesso",
            type: "success"
          });
        } catch (err) {
          this.toggleSnackbar({
            text: this.$t.authentication.login.authentication_failure,
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  @include modal-content-situation();

  .title {
    margin-bottom: 20px;
    left: 0;
    transition: left 0.4s !important;
    -webkit-transition: left 0.4s !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 1.5rem;
      color: #ffffff;

      .icon-back {
        color: #ffffff;
        cursor: pointer;
      }
    }

    .page-subtitle {
      font-size: 1rem;
      color: #ffffff;
      right: 0;
      top: -60px;
    }
  }

  .action-button {
    color: #ffffff;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 6px;
    text-transform: none;
  }

  .header-cards {
    display: flex;
    justify-content: space-between;
  }

  .container-div {
    background: linear-gradient(
      180deg,
      $primary-color-2 0%,
      $primary-color-1 46%,
      $white-1 46%
    );
    padding: 3% 4% 5%;
    min-height: 100vh;
  }

  .sidebarIsShow {
    width: calc(100% - 250px);
    float: right;
  }

  .components-container {
    background: none !important;
    box-shadow: none !important;

    .container-card {
      display: flex;
      width: 100%;

      .container-first-component {
        width: 30%;
      }

      .container-second-component {
        width: 70%;
        margin-left: 2%;

        ::v-deep .table-header {
          position: inherit !important;
        }

        ::v-deep .divider-forms {
          margin-top: 0;
        }
      }
    }

    ::v-deep .theme--light.v-toolbar.v-sheet {
      display: none !important;
      box-shadow: none !important;
    }

    ::v-deep .page-default-card {
      background: none !important;
      box-shadow: none !important;
    }

    ::v-deep .title {
      display: none;
    }

    .first-component {
      width: 100% !important;
      min-width: 450px !important;
      margin-right: 0 !important;
    }

    .second-component {
      width: 100% !important;
      margin-left: 0 !important;

      ::v-deep .divider-forms.v-divider.theme--light {
        margin-top: 103px !important;
      }
    }
  }

  .modal-content-situation {
    ::v-deep .v-list-item::before {
      border-radius: 4px !important;
      font-weight: bold;
    }
  }
</style>
