import { granteeTabs } from "@/modules/stock/destination-reconditioning/enums/granteeTabs.enum";
import { personTypes } from "@/modules/agenda/enums/personTypes.enum";

export const createFormGranteeInitialState = () => {
  return {
    type: personTypes.PJ,
    email: "",
    granteePF: {
      name: "",
      cpf: ""
    },
    granteePJ: {
      fantasyName: "",
      cnpj: "",
      agent: {
        name: "",
        email: "",
        phones: [
          {
            areaCode: "",
            phone: "",
            phoneType: ""
          }
        ]
      }
    },
    phones: [
      {
        areaCode: "",
        phone: "",
        phoneType: ""
      }
    ],
    address: {
      zipCode: null,
      address: null,
      number: null,
      complement: "",
      neighborhood: null,
      city: null,
      state: {}
    }
  };
};

export const createDestinationInitialState = () => {
  return {
    stepper: {
      currentStep: 0,
      maxSteps: 3
    },
    selectedCrc: {
      id: undefined,
      name: undefined
    },
    selectedEquipments: [],
    granteeTab: granteeTabs.REGISTER,
    granteeTabKey: 1,
    granteeInvalid: true,
    selectedGrantee: undefined,
    formGrantee: createFormGranteeInitialState()
  };
};

export const state = {
  data: {
    createDestination: createDestinationInitialState()
  }
};
