export default [
  {
    name: "Home",
    path: "/",
    redirect: "/painel"
  },
  {
    name: "PageNotFound",
    path: "*",
    component: () => import("@/modules/core/views/pages/NotFound.vue")
  }
];
