export const initiaState = () => ({
  data: {
    id: null,
    firstName: null,
    surname: null,
    role: {
      id: null,
      description: null
    },
    photoHash: null,
    selectedCrc: {
      id: null,
      description: null
    },
    avaliableCrcs: [
      {
        id: null,
        description: null
      }
    ]
  }
});

export const state = initiaState();
