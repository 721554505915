export const getCreateDestinationStepper = state =>
  state.data.createDestination.stepper;
export const getCreateDestinationSelectedEquipments = state =>
  state.data.createDestination.selectedEquipments;
export const getCreateDestinationGranteeTab = state =>
  state.data.createDestination.granteeTab;
export const getCreateDestinationFormGrantee = state =>
  state.data.createDestination.formGrantee;
export const getCreateDestinationGranteeTabKey = state =>
  state.data.createDestination.granteeTabKey;
export const getCreateDestinationGranteeInvalid = state =>
  state.data.createDestination.granteeInvalid;
export const getCreateDestinationSelectedGrantee = state =>
  state.data.createDestination.selectedGrantee;
export const getCreateDestinationSelectedCrc = state =>
  state.data.createDestination.selectedCrc;
