import { isNumber } from "lodash";

export default {
  methods: {
    pluralize(value, singular, plural) {
      if (!isNumber(value)) {
        throw new Error("value MUST be a number");
      }
      return value > 1 ? plural : singular;
    }
  }
};
