import { profiles } from "@/modules/core/enums/profiles.enum";
import { ContentLayout } from "@/modules/core/views/templates";

export default [
  {
    path: "/agenda",
    component: ContentLayout,
    sidebar: {
      name: "Agenda",
      icon: "mdi-calendar",
      index: 7,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.RECEPTIONIST.id
      ]
    },
    children: [
      {
        name: "agenda",
        path: "",
        meta: {
          title: "Agenda",
          public: false,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () => import("@/modules/agenda/views/pages/Schedules.vue")
      },
      {
        name: "newRequest",
        path: "nova-solicitacao",
        meta: {
          title: "Nova Solicitação",
          public: false,
          permissions: [profiles.MANAGER_OPERATING.id, profiles.RECEPTIONIST.id]
        },
        component: () => import("@/modules/agenda/views/pages/NewRequest.vue")
      },
      {
        name: "newSchedule",
        path: "novo-agendamento",
        meta: {
          title: "Novo agendamento",
          public: false,
          iconBack: true,
          permissions: [profiles.MANAGER_OPERATING.id]
        },
        component: () => import("@/modules/agenda/views/pages/NewSchedule.vue")
      },
      {
        name: "ViewRequest",
        path: "visualizar-solicitacao/:id(\\d+)",
        meta: {
          title: "Informações",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () => import("@/modules/agenda/views/pages/ViewRequest.vue")
      },
      {
        name: "ViewHistoryRequest",
        path: "visualizar-solicitacao/historico/:id(\\d+)",
        meta: {
          title: "Informações",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id,
            profiles.DRIVER.id,
            profiles.OPERATOR_BATCH.id
          ]
        },
        component: () => import("@/modules/agenda/views/pages/ViewRequest.vue")
      },
      {
        name: "ViewSchedule",
        path: "visualizar-agendamento/:id(\\d+)",
        meta: {
          title: "Detalhamento coleta",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id,
            profiles.DRIVER.id,
            profiles.OPERATOR_BATCH.id
          ]
        },
        component: () => import("@/modules/agenda/views/pages/ViewSchedule.vue")
      }
    ]
  }
];
