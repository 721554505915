import * as MutationTypes from "./mutation-types";
import { createDestinationInitialState } from "./state";

export const mutations = {
  [MutationTypes.SET_CREATE_DESTINATION_CURRENT_STEP](state, currentStep) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        stepper: {
          ...state.data.createDestination.stepper,
          currentStep
        }
      }
    };
  },

  [MutationTypes.RESET_CREATE_DESTINATION](state) {
    state.data = {
      ...state.data,
      createDestination: createDestinationInitialState()
    };
  },

  [MutationTypes.SET_CREATE_DESTINATION_SELECTED_CRC](state, selectedCrc) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        selectedCrc
      }
    };
  },

  [MutationTypes.SET_CREATE_DESTINATION_SELECTED_RESIDUES](
    state,
    selectedResidues
  ) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        selectedResidues: [...selectedResidues]
      }
    };
  },

  [MutationTypes.SET_CREATE_DESTINATION_SELECTED_RECYCLER](
    state,
    selectedRecycler
  ) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        selectedRecycler
      }
    };
  },

  [MutationTypes.SET_CREATE_DESTINATION_RECYCLER_INVALID](
    state,
    residuesInvalid
  ) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        residuesInvalid
      }
    };
  }
};
