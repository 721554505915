export const RESET_CREATE_DESTINATION = "RESET_CREATE_DESTINATION";
export const RESET_CREATE_DESTINATION_GRANTEE =
  "RESET_CREATE_DESTINATION_GRANTEE";
export const SET_CREATE_DESTINATION_CURRENT_STEP =
  "SET_CREATE_DESTINATION_CURRENT_STEP";
export const SET_CREATE_DESTINATION_SELECTED_EQUIPMENTS =
  "SET_CREATE_DESTINATION_SELECTED_EQUIPMENTS";
export const SET_CREATE_DESTINATION_GRANTEE_TAB =
  "SET_CREATE_DESTINATION_GRANTEE_TAB";
export const SET_CREATE_DESTINATION_FORM_GRANTEE =
  "SET_CREATE_DESTINATION_FORM_GRANTEE";
export const SET_CREATE_DESTINATION_GRANTEE_INVALID =
  "SET_CREATE_DESTINATION_GRANTEE_INVALID";
export const SET_CREATE_DESTINATION_SELECTED_GRANTEE =
  "SET_CREATE_DESTINATION_SELECTED_GRANTEE";
export const SET_CREATE_DESTINATION_SELECTED_CRC =
  "SET_CREATE_DESTINATION_SELECTED_CRC";
