import { ContentLayout } from "@/modules/core/views/templates";
import { profiles } from "@/modules/core/enums/profiles.enum";

export default [
  {
    path: "/recicladores",
    component: ContentLayout,
    sidebar: {
      name: "Recicladores",
      icon: "mdi-recycle",
      index: 6,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.RECEPTIONIST.id
      ]
    },
    children: [
      {
        name: "recicladores",
        path: "",
        meta: {
          title: "Recicladores",
          public: false,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () => import("@/modules/recycler/views/pages/Recyclers.vue")
      },
      {
        name: "recyclerDetail",
        path: ":id(\\d+)",
        meta: {
          title: "Reciclador",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () =>
          import("@/modules/recycler/views/pages/ViewRecycler.vue")
      },
      {
        name: "addRecycler",
        path: "novo-reciclador",
        meta: {
          title: "Cadastrar Reciclador",
          subtitle: "Passo 1 de 3",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () =>
          import("@/modules/recycler/views/pages/RegisterRecycler.vue")
      },
      {
        name: "addDocument",
        path: ":id/adicionar-documento",
        meta: {
          title: "Cadastrar Documentos",
          subtitle: "Passo 3 de 3",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () =>
          import("@/modules/recycler/views/pages/RegisterDocument.vue")
      },
      {
        name: "addResidues",
        path: ":id/adicionar-residuo",
        meta: {
          title: "Cadastrar resíduo",
          subtitle: "Passo 2 de 3",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () =>
          import("@/modules/recycler/views/pages/RegisterResidues.vue")
      }
    ]
  }
];
