<template>
  <v-toolbar color="transparent" flat class="the-toolbar" :class="classes">
    <div class="d-flex justify-space-between fill">
      <div>
        <v-icon color="white" @click="toggleSidebar" v-if="!sidebarIsShow"
          >mdi-menu</v-icon
        >
        <v-icon color="white" @click="toggleSidebar" v-if="sidebarIsShow"
          >mdi-menu-open</v-icon
        >
      </div>
      <div class="d-flex justify-space-between align-center white--text">
        <user-photo v-model="user.photoHash" size="sm" border="thin" />
        <span class="ml-2">Olá, {{ user.firstName }}</span>

        <v-menu transition="slide-x-transition" bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="white" v-bind="attrs" v-on="on"
              >mdi-menu-down</v-icon
            >
          </template>

          <v-list>
            <v-list-item @click="userProfile()" class="item-dropdown-user">
              <v-list-item-title>
                <v-icon class="icons-dropdown-user">mdi-account</v-icon
                ><span class="label-dropdown-user">Meu Perfil</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="!userIsAdmin && user.avaliableCrcs.length > 1"
              @click="setSelectCrcDialog(true)"
              class="item-dropdown-user"
            >
              <v-list-item-title>
                <v-icon class="icons-dropdown-user">mdi-sync</v-icon
                ><span class="label-dropdown-user">Alterar CRC</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout" class="item-dropdown-user">
              <v-list-item-title>
                <v-icon class="icons-dropdown-user">mdi-logout</v-icon
                ><span class="label-dropdown-user">Sair</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-toolbar>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import UserPhoto from "@/modules/account/user/views/components/UserPhoto";

  export default {
    name: "PageHeader",

    components: {
      UserPhoto
    },

    computed: {
      ...mapGetters({
        sidebarIsShow: "sidebarIsShow",
        user: "user/getUser",
        userIsAdmin: "user/getUserIsAdmin"
      }),
      classes() {
        return {
          ["the-toolbar-fixed"]: this.$isMobile,
          ["the-toolbar-sidebar-active"]: !this.$isMobile && this.sidebarIsShow,
          ["the-toolbar-background"]: this.$isMobile
        };
      }
    },

    methods: {
      ...mapActions(["toggleSidebar", "setSelectCrcDialog"]),
      ...mapActions("authentication", ["logoutUser"]),
      ...mapActions("user", ["resetUser", "clearUserSelectedCrc"]),

      logout() {
        this.logoutUser();
        this.resetUser();
        this.$router.push({ name: "login" });
      },

      userProfile() {
        return this.$router.push({ name: "UserProfile" });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/components";

  .the-toolbar {
    z-index: 1;
    width: 100%;
  }

  .the-toolbar-fixed {
    position: fixed;
  }

  .the-toolbar-sidebar-active {
    left: $nav-width;
    width: calc(100% - #{$nav-width});
    transition: 0.3s ease-in-out;
  }

  .the-toolbar-background {
    background: linear-gradient(to right, $primary-color-2, $primary-color-1);
  }
</style>
