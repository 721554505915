<template>
  <div>
    <div class="title">
      <h2 class="page-title">
        <v-icon
          @click="$router.back()"
          v-if="$route.meta.iconBack"
          class="icon-back"
          >mdi-arrow-left</v-icon
        >
        {{ $route.meta.title }}
      </h2>
      <h3 class="page-subtitle">{{ $route.meta.subtitle }}</h3>
      <slot name="actionBtn"></slot>
    </div>
    <div>
      <slot name="table"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TabLayout"
  };
</script>

<style lang="scss" scoped>
  .title {
    margin-bottom: 20px;
    left: 0;
    transition: left 0.4s !important;
    -webkit-transition: left 0.4s !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 1.5rem;
      color: #ffffff;

      .icon-back {
        color: #ffffff;
        cursor: pointer;
      }
    }

    .page-subtitle {
      font-size: 1rem;
      color: #ffffff;
      right: 0;
      top: -60px;
    }
  }

  .page-default-card {
    border-radius: 6px;
    box-shadow: 0px 2px 2px #00000029;
    left: 0;
    transition: left 0.4s !important;
    -webkit-transition: left 0.4s !important;
  }
</style>
