import { ContentLayout } from "@/modules/core/views/templates";
import { profiles } from "@/modules/core/enums/profiles.enum";

import store from "@/store";
import router from "@/router";

export default [
  {
    path: "/crc",
    component: ContentLayout,
    sidebar: {
      name: "CRC",
      icon: "mdi-store",
      index: 3,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.RECEPTIONIST.id,
        profiles.DRIVER.id,
        profiles.OPERATOR_BATCH.id,
        profiles.OPERATOR_STOCK.id,
        profiles.OPERATOR_RECONDITION.id,
        profiles.OPERATOR_UNMANUFACTURE.id
      ]
    },
    children: [
      {
        name: "crcs",
        path: "",
        meta: {
          title: "CRC - Centro de Recondicionamento de Computadores",
          public: false,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id,
            profiles.DRIVER.id,
            profiles.OPERATOR_BATCH.id,
            profiles.OPERATOR_STOCK.id,
            profiles.OPERATOR_RECONDITION.id,
            profiles.OPERATOR_UNMANUFACTURE.id
          ]
        },
        beforeEnter: (to, from, next) => {
          const userIsAdmin = store.getters["user/getUserIsAdmin"];
          const selectedCrc = store.getters["user/getSelectedUserCRC"];
          if (from.name === "ViewCRC" && !userIsAdmin) {
            const paramId = parseInt(from.params.id, 10);
            if (paramId === selectedCrc.id) {
              return;
            }
          }
          if (userIsAdmin) {
            return next();
          }
          if (!selectedCrc.id) {
            const user = store.getters["user/getUser"];
            throw new Error(
              `Something went wrong, profile ${user.role.description} MUST have a selected CRC`
            );
          }
          router.push({ name: "ViewCRC", params: { id: selectedCrc.id } });
        },
        component: () => import("@/modules/crc/views/pages/ListCrcsAdmin.vue")
      },
      {
        name: "ViewCRC",
        path: ":id(\\d+)",
        meta: {
          title: "CRC - Centro de Recondicionamento de Computadores",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id,
            profiles.DRIVER.id,
            profiles.OPERATOR_BATCH.id,
            profiles.OPERATOR_STOCK.id,
            profiles.OPERATOR_RECONDITION.id,
            profiles.OPERATOR_UNMANUFACTURE.id
          ]
        },
        components: {
          default: () => import("@/modules/crc/views/pages/ViewCRC.vue")
        }
      },
      {
        name: "ManageEmployees",
        path: ":id(\\d+)/funcionarios",
        meta: {
          title: "Gerenciar Funcionários",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.MANAGER_OPERATING.id
          ]
        },
        component: () => import("@/modules/crc/views/pages/ManageEmployees.vue")
      },
      {
        name: "RegisterCrcs",
        path: "adicionar-crc",
        meta: {
          title: "Cadastrar CRC",
          iconBack: true,
          public: false,
          permissions: [profiles.PRESIDENT.id, profiles.DIRECTOR.id]
        },
        component: () => import("@/modules/crc/views/pages/RegisterCRC.vue")
      }
    ]
  }
];
