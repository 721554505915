import { state } from "@/modules/partner/store/state";
import { states } from "../enums/states.enum";

export const isLoading = state => state.isLoading;
export const snackbarInfo = state => state.snackbarInfo;
export const sidebarIsShow = state => {
  if (state.isLoading) {
    return false;
  }
  return state.sidebar.isShow;
};
export const getItemsTable = state => state.itemsTable;

export const getRoutes = state => state.routes;

export const getSelectCrcDialog = state => state.selectCrcDialog;
