import { profiles } from "@/modules/core/enums/profiles.enum";

export default [
  {
    name: "demanufacturing",
    path: "desmanufatura",
    sidebar: {
      name: "Desmanufatura",
      icon: null,
      index: 4,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.OPERATOR_UNMANUFACTURE.id
      ]
    },
    meta: {
      title: "Desmanufatura",
      public: false,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.OPERATOR_UNMANUFACTURE.id
      ]
    },
    component: () =>
      import("@/modules/stock/demanufacturing/views/pages/ListDemanufacturing")
  },
  {
    name: "demanufacturing-detail",
    path: "desmanufatura/detalhe/:id(\\d+)",
    meta: {
      title: "Detalhar Desmanufatura",
      public: false,
      iconBack: true,
      permissions: [
        profiles.MANAGER_OPERATING.id,
        profiles.OPERATOR_UNMANUFACTURE.id
      ]
    },
    component: () =>
      import(
        "@/modules/stock/demanufacturing/views/pages/DetailDemanufacturing"
      )
  },
  {
    name: "demanufacturing-update",
    path: "desmanufatura/atualizar/:id(\\d+)",
    meta: {
      title: "Atualizar Desmanufatura",
      public: false,
      iconBack: true,
      permissions: [
        profiles.MANAGER_OPERATING.id,
        profiles.OPERATOR_UNMANUFACTURE.id
      ]
    },
    component: () =>
      import(
        "@/modules/stock/demanufacturing/views/pages/UpdateDemanufacturing"
      )
  }
];
