import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import BrandService from "@/modules/stock/batch/service/BrandService";
import BatchService from "@/modules/stock/batch/service/BatchService";
import BatchItemService from "@/modules/stock/batch/service/BatchItemService";
import EquipmentTypeService from "@/modules/stock/batch/service/EquipmentTypeService";
import EquipmentService from "@/modules/stock/batch/service/EquipmentService";
import PartTypeService from "@/modules/stock/batch/service/PartTypeService";
import PartService from "../service/PartService";
import { readFilenameFromContentDisposition } from "@/modules/core/helpers/httpHelpers";
import { downloadFileFromBlob } from "@/modules/core/helpers/fileHelpers";

export const resetCreateState = ({ commit }) => {
  commit(MutationTypes.RESET_CREATE_STATE);
};

export const keepCreate = ({ commit, state }, payload) => {
  const lastFormData = { ...state.data.create.form };
  const restorableFormData = {
    batch: payload?.batch ?? lastFormData.batch
  };

  commit(MutationTypes.RESET_CREATE_STATE);
  commit(MutationTypes.SET_FORM_DATA, restorableFormData);
  commit(MutationTypes.SET_CURRENT_STEP, 2);
  commit(MutationTypes.INCREMENT_CREATE_KEY);
};

export const setCurrentStep = ({ commit }, payload) => {
  commit(MutationTypes.SET_CURRENT_STEP, payload);
};

export const setFormData = ({ commit }, payload) => {
  commit(MutationTypes.SET_FORM_DATA, payload);
};

export const getBrands = loading(async ({ commit }, payload) => {
  const { data } = await BrandService.getBrands(payload);

  return data;
});

export const getBatchsSimplified = loading(async ({ commit }, payload) => {
  const { data } = await BatchService.getBatchsSimplified(payload);

  return data;
});

export const getBatchs = loading(async ({ commit }, payload) => {
  const { data } = await BatchService.getBatchs(payload);

  return data;
});

export const getBatch = loading(async ({ commit }, payload) => {
  const { batchId, filterDemanufacturingItems } = payload;
  const { data } = await BatchService.getBatch(batchId, {
    filterDemanufacturingItems
  });

  return data;
});

export const createBatchItem = async ({ commit }, payload) => {
  const { data } = await BatchItemService.createBatchItem(payload);

  return data;
};

export const getBatchItems = loading(async ({ commit }, payload) => {
  const { batchId, page, limit, filterDemanufacturingItems } = payload;
  const { data } = await BatchService.getBatchItems(batchId, {
    page,
    limit,
    filterDemanufacturingItems
  });

  return data;
});

export const getEquipmentTypes = loading(async ({ commit }, payload) => {
  const { data } = await EquipmentTypeService.getEquipmentTypes(payload);

  return data;
});

export const getEquipments = loading(async ({ commit }, payload) => {
  const { data } = await EquipmentService.get(payload);

  return data;
});

export const createEquipment = loading(async ({ commit }, payload) => {
  const { data } = await EquipmentService.post(payload);

  return data;
});

export const getEquipmentTypesMostUsed = loading(
  async ({ commit }, payload) => {
    const { data } = await EquipmentTypeService.getEquipmentTypesMostUsed(
      payload
    );

    return data;
  }
);

export const getParts = loading(async ({ commit }, payload) => {
  const { data } = await PartService.get(payload);

  return data;
});

export const createPart = loading(async ({ commit }, payload) => {
  const { data } = await PartService.post(payload);

  return data;
});

export const getPartTypes = loading(async ({ commit }, payload) => {
  const { data } = await PartTypeService.getPartTypes(payload);

  return data;
});

export const getPartTypesMostUsed = loading(async ({ commit }, payload) => {
  const { data } = await PartTypeService.getPartTypesMostUsed(payload);

  return data;
});

export const sendBatchItemsToDestiny = async ({ commit }, payload) => {
  const { data } = await BatchItemService.sendBatchItemsToDestiny(payload);

  return data;
};

export const concludeBatch = async ({ commit }, batchId) => {
  const { data } = await BatchService.concludeBatch(batchId);

  return data;
};

export const deleteBatchItem = async ({ commit }, batchItemId) =>
  BatchItemService.delete(batchItemId);

export const donwloadBatchConcludedCertificate = async (
  { commit },
  batchId
) => {
  const {
    data,
    headers
  } = await BatchService.downloadBatchConcludedCertificate(batchId);

  const filename = readFilenameFromContentDisposition(
    headers["content-disposition"],
    "Certificado de destinação.pdf"
  );

  downloadFileFromBlob(data, filename);
};

export const downloadBatchReportPdf = async ({ commit }, batchId) => {
  const { data, headers } = await BatchService.downloadBatchReportPdf(batchId);

  const filename = readFilenameFromContentDisposition(
    headers["content-disposition"],
    "Relatório do lote.pdf"
  );

  downloadFileFromBlob(data, filename);
};

export const downloadBatchReportExcel = async ({ commit }, batchId) => {
  const { data, headers } = await BatchService.downloadBatchReportExcel(
    batchId
  );

  const filename = readFilenameFromContentDisposition(
    headers["content-disposition"],
    "Relatório do lote.xlsx"
  );

  downloadFileFromBlob(data, filename);
};

export const downloadBatchReportCsv = async ({ commit }, batchId) => {
  const { data, headers } = await BatchService.downloadBatchReportCsv(batchId);

  const filename = readFilenameFromContentDisposition(
    headers["content-disposition"],
    "Relatório do lote.csv"
  );

  downloadFileFromBlob(data, filename);
};
