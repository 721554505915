import * as MutationTypes from "./mutation-types";
import {
  createInitialState,
  createContractInitialState,
  viewInitialState
} from "./state";

export const mutations = {
  [MutationTypes.RESET_CREATE_STATE](state) {
    state.data.create = createInitialState();
  },
  [MutationTypes.SET_MAX_STEPS](state, maxSteps) {
    state.data.create.step = {
      ...state.data.create.step,
      maxSteps
    };
  },
  [MutationTypes.SET_CURRENT_STEP](state, currentStep) {
    state.data.create.step = {
      ...state.data.create.step,
      currentStep
    };
  },

  [MutationTypes.ADD_PEV](state, pev) {
    state.data.create = {
      ...state.data.create,
      pevs: [
        ...state.data.create.pevs,
        {
          ...pev,
          localId: state.data.create.pevCount + 1
        }
      ]
    };
  },

  [MutationTypes.REMOVE_PEV](state, localId) {
    state.data.create = {
      ...state.data.create,
      pevs: state.data.create.pevs.filter(pev => pev.localId !== localId)
    };
  },

  [MutationTypes.INCREMENT_PEV_COUNT](state) {
    state.data.create = {
      ...state.data.create,
      pevCount: state.data.create.pevCount + 1
    };
  },

  [MutationTypes.SET_FORM_DATA](state, formData) {
    state.data.create = {
      ...state.data.create,
      form: {
        ...formData
      }
    };
  },

  [MutationTypes.SET_SELECTED_CRC](state, selectedCrc) {
    state.data.create = {
      ...state.data.create,
      selectedCrc
    };
  },

  [MutationTypes.RESET_CONTRACT](state) {
    state.data.create = {
      ...state.data.create,
      form: {
        ...state.data.create.form,
        contract: createContractInitialState()
      }
    };
  },

  [MutationTypes.RESET_PARTNER_VIEW](state) {
    state.data.view = viewInitialState();
  },

  [MutationTypes.SET_PARTNER_VIEW](state, view) {
    state.data.view = { ...view };
  }
};
