import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import UserService from "../service/UserHttpService";
import JWTService from "@/modules/core/service/JWTService";
import { adminProfiles } from "@/modules/core/enums/profiles.enum";

export const createUser = loading(async ({ commit }, payload) => {
  const response = await UserService.createUser(payload);
  return response.data;
});

export const getUsers = loading(async ({ commit }, payload) => {
  const response = await UserService.getUsers(payload);
  return response.data;
});

export const getUser = loading(async ({ commit }, id) => {
  const response = await UserService.getUser(id);
  return response.data;
});

export const getUserToken = loading(async ({ commit }) => {
  const token = JWTService.getToken();
  return `Bearer ${token}`;
});

export const getMe = loading(async ({ commit }) => {
  const response = await UserService.getMe();
  return response.data;
});

export const updateUser = loading(async ({ commit, getters }, payload) => {
  const { data } = await UserService.updateUser(payload);

  // update state if updated user is user logged
  const loggedUser = getters.getUser;
  if (!!loggedUser && loggedUser.id === payload.id) {
    commit(MutationTypes.SET_USER, payload.data);
  }

  return data;
});

export const resendEmail = loading(async ({ commit }, payload) => {
  const response = await UserService.resendEmail(payload);
  return response.data;
});

export const selectCRC = loading(async ({ commit }, id) => {
  const { data } = await UserService.selectCRC(id);

  return data;
});

export const getUserSelectedCRC = loading(async ({ commit }) => {
  const response = await UserService.getSelectedCRC();
  return response.data;
});

export const changeSituation = loading(async ({ commit }, payload) => {
  const response = await UserService.changeSituation(payload);
  return response.data;
});

export const setUser = ({ commit }, user) => {
  commit(MutationTypes.SET_USER, user);
};

export const resetUser = ({ commit }) => {
  commit(MutationTypes.RESET_USER);
};

export const clearUserSelectedCrc = ({ commit }) => {
  commit(MutationTypes.CLEAR_USER_SELECTED_CRC);
};
