import axios from "axios";

import JWTService from "@/modules/core/service/JWTService";
import {
  debounceRequestInterceptor,
  debounceRequestErrorInterceptor
} from "@/modules/core/network/debounce-interceptor";

export const createHttp = (useAuthorization = true, useDebounce = true) => {
  const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
  });

  if (useAuthorization) {
    http.interceptors.request.use(
      config => {
        const token = JWTService.getToken();

        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  if (useDebounce) {
    http.interceptors.request.use(
      debounceRequestInterceptor,
      debounceRequestErrorInterceptor
    );
  }

  http.interceptors.response.use(
    response => response,
    async error => {
      if (useAuthorization && error?.response?.status === 401) {
        JWTService.logout();
        window.location.pathname = "/";
      }
      if (axios.isCancel(error)) {
        return Promise.resolve({});
      }
      return Promise.reject(error.response);
    }
  );

  return http;
};
