export default {
  pev: {
    btnRegisterFirstPev: "Cadastra o primeiro parceiro",
    noRegisteredPEV:
      "Você ainda não cadastrou nenhuma parceiro, deseja cadastrar?",
    titleRegisterPEV: "Adicionar PEV (Ponto de Entrega Voluntaria)"
  },
  home: {
    title: "Home"
  },
  formUser: {
    labels: {
      personalData: "Dados Pessoais",
      companyInformation: "Informações da empresa",
      openingHours: "Horário de funcionamento",
      address: "Endereço",
      zipCode: "CEP",
      number: "Número",
      complement: "Complemento",
      neighborhood: "Bairro",
      city: "Cidade",
      state: "Estado",
      description: "Descricão",
      contact: {
        title: "Contato",
        addPhone: "+ Adicionar",
        delPhone: "Remover",
        phone: "Telefone"
      },
      representative: "Representante",
      firstName: "Nome",
      surname: "Sobrenome",
      companyName: "Nome da empresa",
      contract: "Contrato",
      startDate: "Data início",
      endDate: "Data término",
      cnpj: "CNPJ",
      cpf: "CPF",
      email: "E-mail",
      phoneType: "Tipo de telefone",
      phoneNumber: "Número de telefone",
      birthDate: "Data de nascimento",
      rg: "RG",
      issuingBody: "Órgão expedidor",
      profile: "Perfil",
      labelCNH: "CNH (Carteira de Habilitação Nacional)",
      cnh: "CNH",
      category: "Categoria",
      categoryCnh: "Categoria CNH",
      crc: "CRC",
      next: "Próximo",
      cancel: "Cancelar",
      uninformed: "Não informado"
    },
    errors: {
      requiredInput: "Esse campo é obrigatório",
      invalidCpf: "CPF inválido",
      invalidEmail: "Email inválido",
      submitError: "Aconteceu algum erro ao cadastrar o funcionário"
    }
  },
  uploadFile: {
    title: "Selecionar arquivo do contrato do computador",
    subtitle: "formato recomendado PDF"
  }
};
