import { loading } from "@/modules/core/helpers/loading";
import PendingService from "../service/PendingService";

export const getPending = loading(async ({ commit }, payload) => {
  const { data } = await PendingService.get(payload);

  return data;
});

export const sendBatchItemsToDestiny = loading(async ({ commit }, payload) => {
  const { data } = await PendingService.forwardDesignedArea(payload);

  return data;
});
