<template>
  <v-snackbar
    v-model="snackbarInfo.snackbar"
    :bottom="true"
    :left="false"
    :dark="true"
    :multi-line="true"
    :right="true"
    :timeout="8000"
    :top="false"
  >
    <v-icon
      class="icon"
      color="success"
      v-if="this.snackbarInfo.type === 'success'"
      v-text="'mdi-checkbox-marked-circle-outline'"
    ></v-icon>

    <v-icon
      class="icon"
      color="error"
      v-if="this.snackbarInfo.type === 'error'"
      v-text="'mdi-close-circle-outline'"
    ></v-icon>

    <v-icon
      class="icon"
      color="attention"
      v-if="this.snackbarInfo.type === 'alert'"
      v-text="'mdi-alert-circle-outline'"
    ></v-icon>

    <span class="text-snackbar">
      {{ snackbarInfo.text }}
    </span>

    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="snackbarInfo.snackbar = false">
        <v-icon v-text="'mdi-close'"></v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "Snackbar",

    computed: {
      ...mapGetters({
        snackbarInfo: "snackbarInfo"
      })
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .icon {
    font-size: 30px !important;
    margin-left: 3px;
    margin-right: 14px;
  }

  .text-snackbar {
    width: 85%;
    display: block;
    float: right;
    min-width: 270px;
    max-width: 311px;
    color: #f7f7f7;
  }

  ::v-deep .v-snack__wrapper {
    max-width: 470px !important;
    border-radius: 6px !important;

    .v-snack__content {
      align-items: center !important;
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap !important;
      justify-content: center !important;
    }
  }
</style>
