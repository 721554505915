<template>
  <v-navigation-drawer v-model="drawer" :temporary="$isMobile" fixed>
    <v-list>
      <v-list-item>
        <v-img
          class="img-logo"
          src="@/assets/logotipo-programando-futuro-horizontal.svg"
        ></v-img>
      </v-list-item>
    </v-list>

    <v-divider class="divider"></v-divider>

    <v-list nav dense>
      <v-list-item-group v-model="groups" mandatory v-if="items.length">
        <template v-for="(item, indice) in items">
          <!-- Multiple Items / Group -->
          <template v-if="item.sidebar.multiple">
            <v-list-group
              :key="indice"
              :prepend-icon="item.sidebar.icon"
              :group="item.sidebar.group"
              active-class="group-active-class"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.sidebar.name }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(subItem, i) in item.items"
                :key="i"
                active-class="primary-color-1--text text--accent-4"
                :to="{ name: subItem.name }"
              >
                <v-list-item-icon v-if="subItem.sidebar.icon">
                  <v-icon>{{ subItem.sidebar.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title
                  v-text="subItem.sidebar.name"
                ></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </template>

          <!-- Single Item -->
          <template v-else>
            <v-list-item
              active-class="primary-color-1--text text--accent-4"
              :to="{ name: item.name }"
              :key="indice"
            >
              <v-list-item-icon>
                <v-icon>{{ item.sidebar.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.sidebar.name }}</v-list-item-title>
            </v-list-item>
          </template>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "Sidebar",

    data() {
      return {
        groups: null,
        items: []
      };
    },

    computed: {
      ...mapGetters({
        sidebarIsShow: "sidebarIsShow",
        user: "user/getUser"
      }),

      drawer: {
        get() {
          return this.sidebarIsShow;
        },

        set(value) {
          this.setSidebar(value);
        }
      }
    },

    mounted() {
      this.mountSidebarItems();
    },

    methods: {
      ...mapActions(["toggleSidebar", "setSidebar"]),

      mountSidebarItems() {
        const routeAndPermisions = this.getRouteAndPermissions();

        this.items = this.filterByPermissions(routeAndPermisions).sort(
          this.sortBySidebarIndex
        );
      },

      getRouteAndPermissions() {
        return this.$router.options.routes
          .filter(route => !!route.sidebar)
          .map(route => {
            if (this.isSingleItem(route)) {
              return this.mounteSingleItemFromRoute(route);
            }
            return this.mountMultipleItemsFromRoute(route);
          });
      },

      isSingleItem(route) {
        return !route.sidebar.multiple;
      },

      mounteSingleItemFromRoute(route) {
        const initialRoute = route.children.find(
          routeChildren => routeChildren.path === ""
        );

        const data = {
          sidebar: route.sidebar,
          public: initialRoute.meta.public,
          name: initialRoute.name
        };

        return data;
      },

      mountMultipleItemsFromRoute(route) {
        const allItems = route.children
          .filter(subRoute => !!subRoute.sidebar)
          .map(subRoute => {
            return {
              name: subRoute.name,
              sidebar: subRoute.sidebar,
              public: subRoute.meta.public
            };
          })
          .sort(this.sortBySidebarIndex);

        const items = this.filterByPermissions(allItems);

        const data = {
          sidebar: route.sidebar,
          items,
          active: false
        };

        return data;
      },

      filterByPermissions(routeAndPermisions) {
        return routeAndPermisions.filter(
          route =>
            route.public ||
            route.sidebar.permissions?.includes(this.user.role.id)
        );
      },

      sortBySidebarIndex(first, second) {
        if (first.sidebar.index < second.sidebar.index) {
          return -1;
        }

        if (first.sidebar.index < second.sidebar.index) {
          return 1;
        }

        return 0;
      }
    }
  };
</script>

<style lang="scss">
  .group-active-class {
    color: rgba(0, 0, 0, 0.87) !important;
  }
</style>
