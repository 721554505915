import batch from "@/modules/stock/batch/store";
import pending from "@/modules/stock/pending/store";
import reconditioning from "@/modules/stock/reconditioning/store";
import partInventory from "@/modules/stock/parts-inventory/store";
import destination from "@/modules/stock/destination/store";
import destinationReconditioning from "@/modules/stock/destination-reconditioning/store";
import destinationDemanufacturing from "@/modules/stock/destination-demanufacturing/store";
import demanufacturing from "@/modules/stock/demanufacturing/store";

export default {
  namespaced: true,
  modules: {
    batch,
    pending,
    reconditioning,
    partInventory,
    destination,
    destinationReconditioning,
    destinationDemanufacturing,
    demanufacturing
  }
};
