import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import ResidueSubtypeInventory from "@/modules/stock/destination-demanufacturing/services/ResidueSubtypeInventory";
import DestinationService from "../services/DestinationService";

export const setCreateDestinationCurrentStep = ({ commit }, currentStep) => {
  commit(MutationTypes.SET_CREATE_DESTINATION_CURRENT_STEP, currentStep);
};

export const resetCreateDestination = ({ commit }) => {
  commit(MutationTypes.RESET_CREATE_DESTINATION);
};

export const setCreateDestinationSelectedCrc = ({ commit }, { id, name }) => {
  commit(MutationTypes.SET_CREATE_DESTINATION_SELECTED_CRC, { id, name });
};

export const setCreateDestinationSelectedRecycler = (
  { commit },
  selectedRecycler
) => {
  commit(
    MutationTypes.SET_CREATE_DESTINATION_SELECTED_RECYCLER,
    selectedRecycler
  );
};

export const setCreateDestinationSelectedResidues = (
  { commit },
  selectedResidues
) => {
  commit(MutationTypes.SET_CREATE_DESTINATION_SELECTED_RESIDUES, [
    ...selectedResidues
  ]);
};

export const setCreateDestinationResiduesInvalid = (
  { commit },
  residuesInvalid
) => {
  commit(
    MutationTypes.SET_CREATE_DESTINATION_RECYCLER_INVALID,
    residuesInvalid
  );
};

export const getResidueSubtypesOnInventory = loading(
  async (_param, payload) => {
    const { data } = await ResidueSubtypeInventory.get(payload);

    return data;
  }
);

export const createDestination = loading(async (_param, payload) => {
  const { data } = await DestinationService.post(payload);

  return data;
});
