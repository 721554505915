export const RESET_CREATE_DESTINATION = "RESET_CREATE_DESTINATION";
export const SET_CREATE_DESTINATION_CURRENT_STEP =
  "SET_CREATE_DESTINATION_CURRENT_STEP";
export const SET_CREATE_DESTINATION_SELECTED_CRC =
  "SET_CREATE_DESTINATION_SELECTED_CRC";
export const SET_CREATE_DESTINATION_SELECTED_RESIDUES =
  "SET_CREATE_DESTINATION_SELECTED_RESIDUES";
export const SET_CREATE_DESTINATION_SELECTED_RECYCLER =
  "SET_CREATE_DESTINATION_SELECTED_RECYCLER";
export const SET_CREATE_DESTINATION_RECYCLER_INVALID =
  "SET_CREATE_DESTINATION_RECYCLER_INVALID";
