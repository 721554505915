import HttpService from "@/modules/core/network/http-service";

class BatchService extends HttpService {
  constructor() {
    super("stock/batch");
  }

  getBatchsSimplified(payload) {
    return this.get("simplified", { params: payload });
  }

  getBatchs(payload) {
    return this.get(payload);
  }

  getBatch(batchId, payload) {
    return this.get(batchId, { params: payload });
  }

  getBatchItems(batchId, payload) {
    return this.get(`${batchId}/items`, { params: payload });
  }

  concludeBatch(batchId) {
    return this.patch({}, `${batchId}/conclude`);
  }

  downloadBatchConcludedCertificate(batchId) {
    return this.getBlob(`${batchId}/certificate`);
  }

  downloadBatchReportPdf(batchId) {
    return this.getBlob(`${batchId}/report/pdf`);
  }

  downloadBatchReportExcel(batchId) {
    return this.getBlob(`${batchId}/report/excel`);
  }

  downloadBatchReportCsv(batchId) {
    return this.getBlob(`${batchId}/report/csv`);
  }
}

export default new BatchService();
