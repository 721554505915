export default {
  authentication: {
    logout: "Sair",
    login: {
      title: "Login",
      labelForm: "Entrar",
      labelPassword: "Senha",
      labelUsername: "Usuário",
      labelButtonLogin: "Continuar",
      username_error: "Usuário inválido",
      label_recovery_password: "Esqueceu sua senha?",
      required_field: "O campo é obrigatório",
      authentication_failure:
        "Não foi possível entrar. Por favor, verifique suas credenciais de acesso novamente"
    },
    password_recovery: {
      title: "Recuperar senha",
      subtitle:
        "Esqueceu sua senha? Digite seu e-mail que enviaremos um link para definir uma nova senha",
      labelButtonLogin: "Recuperar",
      labelEmail: "E-mail",
      labelButtonBack: "Voltar"
    },
    recovered_password: {
      title: "E-mail enviado",
      subtitle:
        "Agora é só aguardar em alguns instantes você receberá o e-mail para alterar sua senha.",
      labelButtonBack: "Voltar"
    },
    new_password: {
      subtitle:
        "Você está quase lá! Informe sua nova senha para poder ter acesso novamente a nossa plataforma.",
      labelPassword: "Senha",
      errorDifferentPasswords: "Senhas diferentes",
      errorInvalidPassword: "Senha inválida",
      labelConfirmPassword: "Confirmar senha",
      labelButton: "Continuar"
    }
  }
};
