import { BlankLayout } from "@/modules/core/views/templates";

export default [
  {
    path: "/conta",
    component: BlankLayout,
    meta: {
      title: "Login"
    },
    redirect: "/conta/login",
    hidden: true,
    children: [
      {
        path: "login",
        name: "login",
        meta: {
          title: "Autenticar",
          public: true
        },
        component: () =>
          import(
            /* webpackChunkName: "account-authentication-login" */ "@/modules/account/authentication/views/pages/Login.vue"
          )
      },
      {
        path: "recuperar-senha",
        name: "recoveryPassword",
        meta: {
          title: "Recuperar senha",
          public: true
        },
        component: () =>
          import(
            /* webpackChunkName: "account-authentication-login" */ "@/modules/account/authentication/views/pages/PasswordRecovery.vue"
          )
      },
      {
        path: "redefinir-senha/:token",
        name: "newPassword",
        meta: {
          title: "Nova senha",
          subtitle:
            "Você está quase lá! Informe sua nova senha para poder ter acesso novamente a nossa plataforma.",
          public: true
        },
        component: () =>
          import(
            /* webpackChunkName: "account-authentication-login" */ "@/modules/account/authentication/views/pages/NewPassword.vue"
          )
      },
      {
        path: "completar-cadastro/:token",
        name: "completeRegistration",
        meta: {
          title: "Completar cadastro",
          subtitle:
            "Você foi convidado para ter acesso a nossa plataforma, por favor informe uma senha para concluir seu cadastro.",
          public: true
        },
        component: () =>
          import(
            /* webpackChunkName: "account-authentication-login" */ "@/modules/account/authentication/views/pages/NewPassword.vue"
          )
      }
    ]
  }
];
