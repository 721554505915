import { flatMap } from "lodash";

export const getCreateDestinationStepper = state =>
  state.data.createDestination.stepper;
export const getCreateDestinationSelectedCrc = state =>
  state.data.createDestination.selectedCrc;
export const getCreateDestinationSelectedRecycler = state =>
  state.data.createDestination.selectedRecycler;
export const getCreateDestinationSelectedResidues = state =>
  state.data.createDestination.selectedResidues;
export const getCreateDestinationSelectedResiduesFormatted = state => {
  return flatMap(state.data.createDestination.selectedResidues, residueType => {
    return residueType.selectedResidueSubtypes.map(residueSubtype => {
      return {
        id: residueSubtype.id,
        description: residueSubtype.description,
        selectedWeight: residueSubtype.selectedWeight,
        selectedWeightFormatted: `${residueSubtype.selectedWeight} Kg`,
        residueType: residueSubtype.residueType.description,
        invalid: !!residueSubtype.invalid
      };
    });
  });
};
export const getCreateDestinationResiduesInvalid = state =>
  state.data.createDestination.residuesInvalid;
