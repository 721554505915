import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import EquipmentInventoryService from "@/modules/stock/destination-reconditioning/services/EquipmentInventoryService";
import GranteeService from "@/modules/stock/destination-reconditioning/services/GraanteeService";
import DonateService from "@/modules/stock/destination-reconditioning/services/DonateService";

export const setCreateDestinationCurrentStep = ({ commit }, currentStep) => {
  commit(MutationTypes.SET_CREATE_DESTINATION_CURRENT_STEP, currentStep);
};

export const resetCreateDestination = ({ commit }) => {
  commit(MutationTypes.RESET_CREATE_DESTINATION);
};

export const setCreateDestinationSelectedEquipments = (
  { commit },
  newItems
) => {
  commit(MutationTypes.SET_CREATE_DESTINATION_SELECTED_EQUIPMENTS, newItems);
};

export const resetCreateDestinationSelectedEquipments = ({ commit }) => {
  commit(MutationTypes.SET_CREATE_DESTINATION_SELECTED_EQUIPMENTS, []);
};

export const setCreateDestinationGranteeTab = ({ commit }, granteeTab) => {
  commit(MutationTypes.SET_CREATE_DESTINATION_GRANTEE_TAB, granteeTab);
  commit(MutationTypes.RESET_CREATE_DESTINATION_GRANTEE);
};

export const setCreateDestinationFormGrantee = ({ commit }, formGrantee) => {
  commit(MutationTypes.SET_CREATE_DESTINATION_FORM_GRANTEE, formGrantee);
};

export const setCreateDestinationGranteeInvalid = (
  { commit },
  granteeInvalid
) => {
  commit(MutationTypes.SET_CREATE_DESTINATION_GRANTEE_INVALID, granteeInvalid);
};

export const setCreateDestinationSelectedGrantee = (
  { commit },
  selectedGrantee
) => {
  commit(
    MutationTypes.SET_CREATE_DESTINATION_SELECTED_GRANTEE,
    selectedGrantee
  );
};

export const setCreateDestinationSelectedCrc = ({ commit }, { id, name }) => {
  commit(MutationTypes.SET_CREATE_DESTINATION_SELECTED_CRC, { id, name });
};

export const getEquipmentsFromInventory = loading(
  async ({ commit }, payload) => {
    const { data } = await EquipmentInventoryService.get(payload);

    return data;
  }
);

export const createGrantee = loading(async ({ commit }, payload) => {
  const { data } = await GranteeService.post(payload);

  return data;
});

export const getGranteesSimplified = loading(async ({ commit }, payload) => {
  const { data } = await GranteeService.getSimplified(payload);

  return data;
});

export const getGranteeById = loading(async ({ commit }, id) => {
  const { data } = await GranteeService.get(id);

  return data;
});

export const createDonate = loading(async ({ commit }, payload) => {
  const { data } = await DonateService.post(payload);

  return data;
});
