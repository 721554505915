import { profiles } from "@/modules/core/enums/profiles.enum";

export default [
  {
    name: "parts-inventory",
    path: "banco-pecas",
    sidebar: {
      name: "Banco de Peças",
      icon: null,
      index: 4,
      permissions: [profiles.MANAGER_OPERATING.id, profiles.OPERATOR_STOCK.id]
    },
    meta: {
      title: "Banco de Peças",
      public: false,
      permissions: [profiles.MANAGER_OPERATING.id, profiles.OPERATOR_STOCK.id]
    },
    component: () =>
      import("@/modules/stock/parts-inventory/view/pages/PartsInventory.vue")
  }
];
