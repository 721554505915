import * as MutationTypes from "./mutation-types";
import { initiaState } from "./state";

export const mutations = {
  [MutationTypes.SET_USER](state, user) {
    const avaliableCrcs = user.userRoles.map(userRole => {
      return {
        id: userRole?.crc?.id ?? null,
        description: userRole?.crc?.description ?? null
      };
    });

    const selectedCrc = state.data.selectedCrc;

    state.data = {
      ...state.data,
      id: user.id ?? null,
      firstName: user.firstName ?? null,
      surname: user.surname ?? null,
      photoHash: user?.photo?.hash ?? null,
      selectedCrc: {
        id: user?.selectedCrc?.id ?? selectedCrc.id,
        description: user?.selectedCrc?.description ?? selectedCrc.description
      },
      role: {
        id: user?.userRoles[0]?.role.id ?? null,
        description: user?.userRoles[0]?.role.description ?? null
      },
      avaliableCrcs: [...avaliableCrcs]
    };
  },

  [MutationTypes.RESET_USER](state) {
    state = initiaState();
  },

  [MutationTypes.CLEAR_USER_SELECTED_CRC](state) {
    state.data = {
      ...state.data,
      selectedCrc: {
        id: null,
        description: null
      }
    };
  }
};
