export const createContractInitialState = () => {
  return {
    beginAt: undefined,
    endAt: undefined,
    file: {
      id: undefined
    }
  };
};

export const createInitialState = () => {
  return {
    step: {
      currentStep: 1,
      maxSteps: null
    },
    form: {
      companyName: undefined,
      cnpj: undefined,
      agent: {
        name: undefined,
        email: undefined,
        phones: []
      },
      contract: createContractInitialState()
    },
    selectedCrc: undefined,
    pevs: [],
    pevCount: 0
  };
};

export const viewInitialState = () => {
  return {
    id: null,
    cnpj: null,
    companyName: null,
    createdAt: null,
    updatedAt: null,
    removedAt: null,
    contracts: [],
    agent: {
      id: null,
      name: null,
      email: null,
      phones: []
    },
    voluntaryDeliveryPoints: [],
    situation: null
  };
};

export const initialState = () => {
  return {
    data: {
      create: createInitialState(),
      view: viewInitialState()
    }
  };
};

export const state = initialState();
