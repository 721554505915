import HttpService from "@/modules/core/network/http-service";

class DemanufacturingService extends HttpService {
  constructor() {
    super("stock/demanufacturing");
  }

  sendResidueStock({ id, body }) {
    return this.patch(body, `${id}/send-stock`);
  }

  conclude(id) {
    return this.patch({}, `${id}/conclude`);
  }
}

export default new DemanufacturingService();
