import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        ["primary-color-1"]: "#ff934c",
        ["primary-color-2"]: "#ea5726",
        ["secondary-color-1"]: "#3ca897",
        ["black-1"]: "#263238",
        ["black-2"]: "#455a64",
        ["black-3"]: "#607d8b",
        ["black-4"]: "#90a4ae",
        ["white"]: "#ffffff",
        ["white-1"]: "#eceff1",
        ["white-2"]: "#b0bec5",
        ["white-3"]: "#78909c",
        ["error"]: "#ff444e",
        ["success"]: "#8add46",
        ["attention"]: "#fcdb46",
        ["information"]: "#38bcff",
        ["situation-1"]: "#2ac2c2",
        ["situation-2"]: "#8add46",
        ["situation-3"]: "#90a4ae",
        ["situation-4"]: "#3CA897",
        ["situation-5"]: "#FF444E",
        ["secondary-text"]: "#757575"
      }
    }
  }
});
