<template>
  <v-app>
    <sidebar v-if="!$route.meta.public" />
    <v-main class="main-app">
      <router-view />
      <confirm-dialog ref="confirm" />
    </v-main>
  </v-app>
</template>

<script>
  import ConfirmDialog from "@/modules/core/views/components/ConfirmDialog";
  import Sidebar from "@/modules/core/views/components/Sidebar";

  export default {
    name: "App",
    components: { ConfirmDialog, Sidebar },
    mounted() {
      this.$root.$confirm = this.$refs.confirm;
    },
    data: () => ({
      //
    })
  };
</script>
<style lang="scss">
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/forms";
  @import "@/assets/stylesheets/styles";

  .main-app {
    background-color: $background;
    z-index: 0;
  }
</style>
