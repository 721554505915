import * as MutationTypes from "./mutation-types";
import { loading } from "@/modules/core/helpers/loading";
import RecyclerHttpService from "../service/RecyclerHttpService";
import ResiduesHttpService from "../service/ResiduesHttpService";

export const resetCreateState = ({ commit }) => {
  commit(MutationTypes.RESET_CREATE_STATE);
};

export const setCurrentStep = ({ commit }, currentStep) => {
  commit(MutationTypes.SET_CURRENT_STEP, currentStep);
};

export const setFormData = ({ commit }, payload) => {
  commit(MutationTypes.SET_FORM_DATA, payload);
};

export const setMaxSteps = ({ commit }, maxSteps) => {
  commit(MutationTypes.SET_MAX_STEPS, maxSteps);
};

export const resetCreateDocuments = ({ commit }) => {
  commit(MutationTypes.RESET_CREATE_DOCUMENTS);
};

export const setCreateDocumentRecyclerId = ({ commit }, id) => {
  commit(MutationTypes.SET_CREATE_DOCUMENTS_RECYCLER_ID, id);
};

export const setCreateDocumentItems = ({ commit }, items) => {
  commit(MutationTypes.SET_CREATE_DOCUMENTS_ITEMS, items);
};

export const setCreateResiduesItems = ({ commit }, items) => {
  commit(MutationTypes.SET_CREATE_RESIDUES_ITEMS, items);
};

export const createRecycler = loading(async ({ commit }, payload) => {
  const { data } = await RecyclerHttpService.createRecycler(payload);
  return data;
});

export const getRecyclerDocuments = loading(
  async ({ commit }, { id, params }) => {
    const { data } = await RecyclerHttpService.getRecyclerDocument(id, params);
    return data;
  }
);

export const getRecyclerDocumentType = loading(async ({ commit }, id) => {
  const { data } = await RecyclerHttpService.getRecyclerDocumentType(id);
  return data;
});

export const toggleRecyclerStatus = loading(async ({ commit }, id) => {
  const { data } = await RecyclerHttpService.toggleRecyclerStatus(id);
  return data;
});

export const getRecyclers = loading(async ({ commit }, payload) => {
  const { data } = await RecyclerHttpService.getRecyclers(payload);
  return data;
});

export const addDocumentRecycler = loading(async ({ commit }, payload) => {
  const { data } = await RecyclerHttpService.addDocumentRecycler(payload);
  return data;
});

export const getRecycler = loading(async ({ commit }, id) => {
  const { data } = await RecyclerHttpService.getRecycler(id);
  return data;
});

export const updateRecycler = loading(async ({ commit }, payload) => {
  const { data } = await RecyclerHttpService.updateRecycler(
    payload.id,
    payload.body
  );
  return data;
});

export const getResidues = loading(async ({ commit }) => {
  const { data } = await ResiduesHttpService.getResidues();
  return data;
});

export const deleteRecycler = loading(async ({ commit }, id) => {
  await RecyclerHttpService.delete(id);
});
