import HttpService from "@/modules/core/network/http-service";

class CollectionRequestHttpService extends HttpService {
  constructor() {
    super("collection-request-city");
  }

  getCitys() {
    return this.get({ situations: "3, 2" });
  }
}

export default new CollectionRequestHttpService();
