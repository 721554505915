<template>
  <div class="d-flex flex-wrap justify-space-between align-baseline mb-5">
    <div class="d-flex">
      <v-icon
        v-if="$route.meta.iconBack"
        color="white"
        @click="$router.back()"
        left
        >mdi-arrow-left</v-icon
      >
      <h1>{{ headerTitle }}</h1>
    </div>
    <h3 v-if="subtitle" class="white--text">{{ headerSubtitle }}</h3>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: false
      },
      subtitle: {
        type: String,
        required: false
      }
    },
    computed: {
      headerTitle() {
        return this.title ?? this.$route.meta.title;
      },
      headerSubtitle() {
        return this.subtitle ?? this.$router.meta.subtitle;
      }
    }
  };
</script>
<style lang="scss" scoped></style>
