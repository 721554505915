import HttpService from "@/modules/core/network/http-service";

class ResiduesHttpService extends HttpService {
  constructor() {
    super("residue-type");
  }

  getResidues() {
    return this.get();
  }
}

export default new ResiduesHttpService();
