import * as MutationTypes from "./mutation-types";
import {
  createDestinationInitialState,
  createFormGranteeInitialState
} from "./state";

export const mutations = {
  [MutationTypes.SET_CREATE_DESTINATION_CURRENT_STEP](state, currentStep) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        stepper: {
          ...state.data.createDestination.stepper,
          currentStep
        }
      }
    };
  },

  [MutationTypes.RESET_CREATE_DESTINATION](state) {
    state.data = {
      ...state.data,
      createDestination: createDestinationInitialState()
    };
  },

  [MutationTypes.RESET_CREATE_DESTINATION_GRANTEE](state) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        formGrantee: createFormGranteeInitialState(),
        granteeTabKey: state.data.createDestination.granteeTabKey + 1,
        granteeInvalid: true,
        selectedGrantee: undefined
      }
    };
  },

  [MutationTypes.SET_CREATE_DESTINATION_SELECTED_EQUIPMENTS](state, newItems) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        selectedEquipments: newItems
      }
    };
  },

  [MutationTypes.SET_CREATE_DESTINATION_GRANTEE_TAB](state, granteeTab) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        granteeTab
      }
    };
  },

  [MutationTypes.SET_CREATE_DESTINATION_FORM_GRANTEE](state, formGrantee) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        formGrantee
      }
    };
  },

  [MutationTypes.SET_CREATE_DESTINATION_GRANTEE_INVALID](
    state,
    granteeInvalid
  ) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        granteeInvalid
      }
    };
  },

  [MutationTypes.SET_CREATE_DESTINATION_SELECTED_GRANTEE](
    state,
    selectedGrantee
  ) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        selectedGrantee
      }
    };
  },

  [MutationTypes.SET_CREATE_DESTINATION_SELECTED_CRC](state, selectedCrc) {
    state.data = {
      ...state.data,
      createDestination: {
        ...state.data.createDestination,
        selectedCrc
      }
    };
  }
};
