import HttpService from "@/modules/core/network/http-service";

class PartTypeService extends HttpService {
  constructor() {
    super("stock/part/part-type");
  }

  getPartTypes(payload) {
    return this.get(payload);
  }

  getPartTypesMostUsed(payload) {
    return this.get("most-used", { params: payload });
  }
}

export default new PartTypeService();
