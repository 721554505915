import Vue from "vue";
import _ from "lodash";
import Router from "vue-router";

import store from "./store";
import routerPaths from "@/routerPaths.js";
import JWTService from "@/modules/core/service/JWTService";
import { firstPageByUser } from "@/modules/core/enums/profiles.enum";

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  routes: routerPaths
});

router.beforeEach((to, from, next) => {
  const user = store.getters["user/getUser"];

  if (to.meta.public === false && !JWTService.isAuthenticated()) {
    return next({ name: "login" });
  }

  const routeToLoginButUserIsLogged =
    to.name === "login" && JWTService.isAuthenticated();

  const userDontHavePermissionRouteTo =
    !_.isEmpty(to.meta.permissions) &&
    !to.meta.permissions.includes(user.role.id);

  if (routeToLoginButUserIsLogged || userDontHavePermissionRouteTo) {
    const firstPage = firstPageByUser[user.role.id];
    return next({ name: firstPage });
  }

  next();
});

export default router;
