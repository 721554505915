import { ContentLayout } from "@/modules/core/views/templates";
import { profiles } from "@/modules/core/enums/profiles.enum";

export default [
  {
    path: "/parceiros",
    component: ContentLayout,
    sidebar: {
      name: "Parceiros e PEVs",
      icon: "mdi-home-city",
      index: 5,
      permissions: [
        profiles.PRESIDENT.id,
        profiles.DIRECTOR.id,
        profiles.PUBLIC_RELATIONS_DIRECTOR.id,
        profiles.MANAGER_OPERATING.id,
        profiles.RECEPTIONIST.id
      ]
    },
    children: [
      {
        name: "partner",
        path: "",
        meta: {
          title: "Parceiros e PEVs",
          public: false,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id,
            profiles.DRIVER.id,
            profiles.OPERATOR_BATCH.id
          ]
        },
        component: () => import("@/modules/partner/views/pages/Partners.vue")
      },
      {
        name: "DetailPartner",
        path: ":id(\\d+)",
        meta: {
          title: "Parceiro",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.PUBLIC_RELATIONS_DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id,
            profiles.DRIVER.id,
            profiles.OPERATOR_BATCH.id
          ]
        },
        component: () => import("@/modules/partner/views/pages/ViewPartner.vue")
      },
      {
        name: "addPartner",
        path: "novo-parceiro",
        meta: {
          title: "Cadastrar Parceiro",
          public: false,
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () =>
          import("@/modules/partner/views/pages/RegisterPartner.vue")
      },
      {
        name: "addPEV",
        path: "novo-parceiro/pev",
        meta: {
          title: "Cadastrar PEV",
          subtitle: "Passo 2 de 3",
          public: false,
          sidebar: "PEV",
          iconBack: true,
          permissions: [
            profiles.PRESIDENT.id,
            profiles.DIRECTOR.id,
            profiles.MANAGER_OPERATING.id,
            profiles.RECEPTIONIST.id
          ]
        },
        component: () => import("@/modules/partner/views/pages/RegisterPEV.vue")
      }
      // this need complete refactor
      // {
      //   name: "AddCRCPartner",
      //   path: "novo-parceiro/crc",
      //   meta: {
      //     title: "Cadastrar Parceiro",
      //     subtitle: "Passo 3 de 3",
      //     public: true,
      //     sidebar: "PEV",
      //     iconBack: true
      //   },
      //   component: () =>
      //     import("@/modules/account/user/views/pages/FormUserCRC.vue")
      // }
    ]
  }
];
